<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="800"
    >
      <v-card
        flat
      >
        <v-card-title>
          <v-icon class="mr-2">far fa-credit-card</v-icon> Asociar Cobros Online
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false" title="Cerrar"><v-icon>fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" class="py-0">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="headers"
                :items="detalles_forma_pago"
                :loading="load"
                dense
                :search="search"
              >
                <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                <template
                  v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                  v-slot:[`item.${header.value}`]="{ value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="3">
                      <SearchDataTable
                        v-model="search"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- Sin Datos para mostrar -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    No hay financiaciones disponibles
                  </v-alert>
                </template>
                <!-- Forma de Pago -->
                <template v-slot:[`item.select_financiacion`]="{ item }">
                  <v-autocomplete
                    v-model="item.select_financiacion"
                    item-text="descripcion"
                    item-value="codigo"
                    :items="item.financiaciones"
                    hide-details
                    outlined
                    dense
                    clearable
                  ></v-autocomplete>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="warning" @click="activo = false">Cancelar</v-btn>
          <v-btn color="success" @click="verifyFP()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import { format_money, cadenaVacia } from '../../util/utils'
export default {
  props: {
    datos: {
      type: Object,
      default(){
        return {}
      }
    },
  },
  components: {
    SearchDataTable,
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModAsocFP', value)
      }
    },
    cobros_asociar: {
      get(){
        return this.datos.cobros_asociar
      }
    },
    detalles_forma_pago: {
      get(){
        return this.datos.detalles_forma_pago
      }
    }
  },
  data() {
    return {
      headers: [
        { text: 'Referencia', align: 'left', value: 'referencia' },
        { text: 'Importe', align: 'right', value: 'importe', formatter: format_money },
        { text: 'Forma de Pago', align: 'left', value: 'select_financiacion' },
      ],
      load: false,
      search: ''
    }
  },
  methods: {
    verifyFP(){
      let incompletos = this.detalles_forma_pago.filter(element => cadenaVacia(element.select_financiacion))
      if (incompletos.length > 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe completar las Formas de Pago para poder agregar.',
          color: 'warning',
        })
        return
      }
      this.activo = false
      this.$emit('fpComplete')
    }
  },
}
</script>

<style>

</style>