<template>
<div>
  <v-row class="d-flex justify-center mt-0 mx-md-0 mx-n1 mb-0">
    <!-- Nro de Reserva -->
    <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1 pb-2">
      <div
        style="font-size: 18px;"
        v-text="`Reserva N°: ${reserva_id}`"
      ></div>
      <v-spacer></v-spacer>
    </v-col>
    <!-- Estado, fecha y Reabrir -->
    <v-col cols="12" class="px-1 pt-2">
      <v-row>
        <v-col :cols="reserva.reserva_estado_id == 5 ? '12' : '6'" xs="" md="4">
          Estado
          <v-text-field
            v-model="reserva.reserva_estado"
            outlined
            dense
            type="text"
            :readonly="true"
            :filled="true"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="2">
          Fecha de reserva
          <FechaPickerVue
            v-model="reserva.reserva_fecha"
            dense
            disabled
            hide-details
          />
        </v-col>
        <v-col cols="6" md="4" v-if="reserva.reserva_estado_id == 5 && puedeReabrir == 1 && permisoReabrir == 1" class="d-flex justify-star pt-6">
          <v-btn
            class=""
            color="info"
            title="Reabrir reserva"
            @click="reabrirReserva()"
          >
            REABRIR
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <!-- Datos del cliente -->
    <v-col cols="12" class="pt-0 pb-1 px-1">
      <v-expansion-panels v-model="panel" class="">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="pt-2 pb-2 py-0 px-4 text-h6" style="cursor: default">
            <div>
              Datos Cliente
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2 pb-2">
            <v-row>
              <v-col cols="6" sm="3" md="3" class="py-2">
                Apellido
                <v-text-field
                  v-model="reserva.cliente_apellido"
                  outlined
                  dense
                  type="text"
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3" class="py-2">
                Nombre
                <v-text-field
                  v-model="reserva.cliente_nombre"
                  outlined
                  dense
                  type="text"
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1 "
                  :filled="reserva.reserva_estado_id != 1 "
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="2"  class="py-2">
                Fec. de Nacimiento
                <FechaPickerVue
                  v-model="reserva.fecha_nacimiento"
                  :disabled="reserva.reserva_estado_id != 1 "
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="6" sm="3" md="2"  class="py-2">
                Tipo Documento
                <v-autocomplete
                  v-model="reserva.tipo_documento"
                  item-text="nombre"
                  item-value="id"
                  :items="tipos_documento"
                  hide-details
                  outlined
                  dense
                  :readonly="reserva.reserva_estado_id != 1 "
                  :filled="reserva.reserva_estado_id != 1 "
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-2">
                DNI/Pasaporte
                <v-text-field
                  v-model="reserva.cliente_nif"
                  outlined
                  dense
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1 "
                  :filled="reserva.reserva_estado_id != 1 "
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3"  class="py-2">
                Cond. de IVA
                <v-autocomplete
                  v-model="reserva.cliente_cond_iva"
                  item-text="condicion_iva_nombre"
                  item-value="condicion_iva_codigo"
                  :items="condiciones_iva"
                  hide-details
                  outlined
                  dense
                  :readonly="reserva.reserva_estado_id != 1 "
                  :filled="reserva.reserva_estado_id != 1"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="3" md="3"  class="py-2">
                País
                <v-text-field
                  v-model="reserva.cliente_pais"
                  outlined
                  dense
                  type="text"
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-2">
                Provincia
                <v-text-field
                  v-model="reserva.cliente_provincia"
                  outlined
                  dense
                  type="text"
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-2">
                Ciudad
                <v-text-field
                  v-model="reserva.cliente_ciudad"
                  outlined
                  dense
                  type="text"
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-2">
                Código Postal
                <v-text-field
                  v-model="reserva.cliente_cp"
                  outlined
                  dense
                  type="number"
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3"  class="py-1">
                Domicilio
                <v-text-field
                  v-model="reserva.cliente_domicilio" 
                  outlined
                  dense
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="4"  class="py-1">
                Email
                <v-text-field
                  v-model="reserva.cliente_email"
                  outlined
                  dense
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-1">
                Teléfono
                <v-text-field
                  v-model="reserva.cliente_telefono"
                  outlined
                  dense
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3"  class="py-1">
                Canal
                <v-autocomplete
                  v-model="reserva.origen_id"
                  item-text="canal_nombre"
                  item-value="canal_id"
                  :items="canales"
                  hide-details
                  outlined
                  dense
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="3" md="3"  class="py-2">
                Licencia N°
                <v-text-field
                  v-model="reserva.cliente_licencia"
                  outlined
                  dense
                  type="text"
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3"  class="py-1">
                Vencimiento
                <FechaPickerVue
                  v-model="reserva.cliente_licencia_vto"
                  dense
                  :disabled="reserva.reserva_estado_id != 1"
                  hide-details
                />
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="6" sm="3" md="3"  class="py-2">
                Cond. de IVA
                <v-autocomplete
                  v-model="reserva.cliente_cond_iva"
                  item-text="condicion_iva_nombre"
                  item-value="condicion_iva_codigo"
                  :items="condiciones_iva"
                  hide-details
                  outlined
                  dense
                  :readonly="reserva.reserva_estado_id != 1 "
                  :filled="reserva.reserva_estado_id != 1"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="3" md="3"  class="py-2">
                País
                <v-text-field
                  v-model="reserva.cliente_pais"
                  outlined
                  dense
                  type="text"
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-2">
                Provincia
                <v-text-field
                  v-model="reserva.cliente_provincia"
                  outlined
                  dense
                  type="text"
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-2">
                Ciudad
                <v-text-field
                  v-model="reserva.cliente_ciudad"
                  outlined
                  dense
                  type="text"
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-2">
                Código Postal
                <v-text-field
                  v-model="reserva.cliente_cp"
                  outlined
                  dense
                  type="number"
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
            </v-row> -->
            <!-- <v-row>
              <v-col cols="6" sm="3" md="3"  class="py-1">
                Domicilio
                <v-text-field
                  v-model="reserva.cliente_domicilio" 
                  outlined
                  dense
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="4"  class="py-1">
                Email
                <v-text-field
                  v-model="reserva.cliente_email"
                  outlined
                  dense
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-1">
                Teléfono
                <v-text-field
                  v-model="reserva.cliente_telefono"
                  outlined
                  dense
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3"  class="py-1">
                Canal
                <v-autocomplete
                  v-model="reserva.origen_id"
                  item-text="canal_nombre"
                  item-value="canal_id"
                  :items="canales"
                  hide-details
                  outlined
                  dense
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-autocomplete>
              </v-col>
            </v-row> -->
            <!-- <v-row>
              <v-col cols="6" sm="3" md="3"  class="py-2">
                Licencia N°
                <v-text-field
                  v-model="reserva.cliente_licencia"
                  outlined
                  dense
                  type="text"
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3"  class="py-1">
                Vencimiento
                <FechaPickerVue
                  v-model="reserva.cliente_licencia_vto"
                  dense
                  :disabled="reserva.reserva_estado_id != 1"
                  hide-details
                />
              </v-col>
            </v-row> -->
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <!-- Datos de la Empresa (Cliente) -->
    <v-col cols="12" class="pt-0 pb-1 px-1">
      <v-expansion-panels v-model="panel2" class="">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="pt-2 pb-2 py-0 px-4 text-h6" style="cursor: default">
            <div>
              Datos Empresa
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel2 = panel2 === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2 pb-2">
            <v-row>
              <v-col cols="6" sm="3" md="3"  class="py-1">
                Cuit
                <v-text-field-integer
                  v-model.trim="reserva.empresa_cuit"
                  v-bind:properties="{
                    'hide-details': true,
                    tabindex: 1,
                    readonly: reserva.reserva_estado_id != 1,
                    filled: reserva.reserva_estado_id != 1,
                    outlined: true,
                    dense: true
                  }"
                  v-bind:options="{
                    inputMask: '##-########-#',
                    outputMask: '###########',
                    empty: null
                  }"
                />
              </v-col>
              <v-col cols="6" sm="3" md="3"  class="py-1">
                Nombre
                <v-text-field
                  v-model="reserva.empresa_nombre"
                  outlined
                  dense
                  type="text"
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3"  class="py-1">
                Domicilio
                <v-text-field
                  v-model="reserva.empresa_domicilio"
                  outlined
                  dense
                  type="text"
                  hide-details
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col  cols="6" sm="3" md="3"  class="py-1">
                ConD. IVA
                <v-autocomplete
                  v-model="reserva.empresa_cond_iva"
                  item-text="condicion_iva_nombre"
                  item-value="condicion_iva_codigo"
                  :items="condiciones_iva"
                  hide-details
                  outlined
                  dense
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <!-- Datos de Entrega y Devolución -->
    <v-col cols="12" class="pt-0 pb-1 px-1">
      <v-expansion-panels v-model="panel3" class="">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="pt-2 pb-2 py-0 px-4 text-h6" style="cursor: default">
            <div>
              Datos de Entrega y Devolución
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel3 = panel3 === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2 pb-3">
            <v-row>
              <v-col cols="6" sm="6" md="6"  class="py-1">
                <v-row>
                  <v-col cols="12" class="py-1">
                    Lugar Entrega
                    <v-autocomplete
                      v-model="reserva.lugar_retiro_id"
                      item-text="lugar_nombre"
                      item-value="lugar_id"
                      :items="lugares"
                      hide-details
                      outlined
                      dense
                      :readonly="reserva.reserva_estado_id != 1"
                      :filled="reserva.reserva_estado_id != 1"
                      @change="detPtoVta()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6"  class="py-1">
                    Fecha Entrega
                    <FechaPickerVue
                      v-model="reserva.retiro_dia"
                      dense
                      :disabled="true"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6"  class="py-1">
                    Hora Entrega
                    <HoraPickerVue
                      v-model="reserva.retiro_hora"
                      :disabled="reserva.reserva_estado_id != 1"
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" sm="6" md="6"  class="py-1">
                <v-row>
                  <v-col cols="12" class="py-1">
                    Lugar Devolución
                    <v-autocomplete
                      v-model="reserva.lugar_entrega_id"
                      item-text="lugar_nombre"
                      item-value="lugar_id"
                      :items="lugares"
                      hide-details
                      outlined
                      dense
                      :readonly="reserva.reserva_estado_id != 1"
                      :filled="reserva.reserva_estado_id != 1"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6"  class="py-1">
                    Fecha Devolución
                    <FechaPickerVue
                      v-model="reserva.entrega_dia"
                      dense
                      :disabled="true"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6"  class="py-1">
                    Hora Devolución
                    <HoraPickerVue
                      v-model="reserva.entrega_hora"
                      :disabled="reserva.reserva_estado_id != 1"
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="pt-0">
              <v-col cols="12" sm="6" md="3"  class="py-0">
                Cantidad Días
                <v-text-field
                  v-model="reserva.dias"
                  outlined
                  dense
                  hide-details
                  :readonly="true"
                  :filled="true"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <!-- Datos del Vehículo -->
    <v-col cols="12" class="pt-0 pb-1 px-1" v-if="instancia != 'prefactura'">
      <v-expansion-panels v-model="panel4" class="">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="pt-2 pb-2 py-0 px-4 text-h6" style="cursor: default">
            <div>
              Datos del Vehículo
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel4 = panel4 === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2 pb-2">
            <v-row>
              <v-col cols="6" sm="4" md="4"  class="py-1">
                Marca
                <v-autocomplete
                  v-model="reserva.marca_id"
                  item-text="marca_nombre"
                  item-value="marca_id"
                  :items="marcas"
                  hide-details
                  outlined
                  dense
                  :readonly="true"
                  :filled="true"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="4" md="4"  class="py-1">
                Modelo
                <v-autocomplete
                  v-model="reserva.vehiculo_modelo_id"
                  item-text="modelo_nombre"
                  item-value="modelo_id"
                  :items="modelos"
                  hide-details
                  outlined
                  dense
                  :readonly="true"
                  :filled="true"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="4" md="4"  class="py-1">
                Dominio
                <v-autocomplete
                  v-model="reserva.vehiculo_id"
                  item-text="matricula"
                  item-value="vehiculo_id"
                  :items="vehiculos"
                  hide-details
                  outlined
                  dense
                  readonly
                  filled
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="pt-2">
              <v-col cols="6" sm="6" md="4"  class="py-2">
                Km Inicio
                <v-text-field
                  v-model="reserva.kilometro_inicio"
                  outlined
                  dense
                  hide-details
                  type="number"
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="4"  class="py-2">
                Km Fin
                <v-text-field
                  v-model="reserva.kilometro_fin"
                  outlined
                  dense
                  hide-details
                  type="number"
                  :readonly="reserva.reserva_estado_id != 2"
                  :filled="reserva.reserva_estado_id != 2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <!-- Extras Check In y Prefactura -->
    <v-col cols="12" class="pt-0 pb-0 px-1" v-if="reserva.reserva_estado_id==1? true: extras_ci_p.length!=0">
      <v-expansion-panels v-model="panel6" class="">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="pt-2 pb-2 py-0 px-4 text-h6" style="cursor: default">
            <div>
              Extras
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel6 = panel6 === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2 pb-2">
            <v-row v-if="reserva.reserva_estado_id == 1">
              <v-col cols="6" sm="4" md="4"  class="py-1">
                Tipo
                <v-autocomplete
                  v-model="filtroExtras.extra_id"
                  item-text="nombre"
                  item-value="extra_id"
                  :items="todos_extras_disp"
                  hide-details
                  outlined
                  dense
                  clearable
                  @change="rellanarExtrasCampos(2)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-1">
                Cantidad
                <v-text-field
                  v-model="filtroExtras.cantidad"
                  outlined
                  dense
                  hide-details
                  type="number"
                  :readonly="reserva.reserva_estado_id != 1"
                  :filled="reserva.reserva_estado_id != 1"
                  @change="recalcularCantidadPrecioExtra(2)"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-1">
                Precio
                <TextFieldMoneyVue
                  v-model="filtroExtras.precio"
                  @change="recalcularCantidadPrecioExtra(2)"
                  v-bind:properties="{prefix: '$', 'hide-details': true, 'validate-on-blur': boolString == 'true'}"
                />
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-1">
                Importe
                <TextFieldMoneyVue
                  v-model="filtroExtras.importe"
                  :key="acuAux"
                  v-bind:properties="{prefix: '$', 'hide-details': true, 'readonly': true, 'filled': true, 'validate-on-blur': boolString == 'true' }"
                />
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-2">
                <v-btn class="mt-4" color="info" @click="agregarExtra(2)">
                  AGREGAR
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="">
              <v-col cols="12" class="pt-0 pb-0">
                <v-data-table
                  class="cebra elevation-2 mt-2"
                  :headers="header"
                  :items="extras_ci_p"
                  :loading="loadCI"
                  dense
                  :search="searchCI"
                  item-key="extra_id"
                  hide-default-footer
                  :items-per-page="-1"
                >
                  <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                  <template
                    v-for="header in header.filter((header) => header.hasOwnProperty('formatter'))"
                    v-slot:[`item.${header.value}`]="{ value }"
                  >
                    {{ header.formatter(value) }}
                  </template>
                  <!-- Acciones -->
                  <template v-slot:[`item.accion`]="{ item }">
                    <v-btn icon small @click="quitarExtra(item)"  :disabled="reserva.reserva_estado_id == 2" v-if="item.a_facturar == 0 && reserva.reserva_estado_id!=3 && reserva.reserva_estado_id!=5"><v-icon small color="error" title="Quitar extra">fas fa-times</v-icon></v-btn>
                  </template>
                  <!-- ultima row para el total -->
                  <template slot="body.append">
                    <tr class="font-weight-bold">
                      <th colspan="3" style="font-size: 0.9em;"  class="text-right">TOTAL</th>
                      <th class="text-center" style="font-size: 0.9em;" colspan="3">
                        <div class="pr-13">
                          {{ format_money(totalExtras) }}
                        </div>
                      </th>
                    </tr>
                  </template>
                  <!-- Msj que se mostrara si no existen resultados -->
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No hay extras para mostrar
                    </v-alert>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <!-- Extras Check Out -->
    <v-col cols="12" class="pt-0 pb-1 px-1" v-if="instancia != 'prefactura'? (reserva.reserva_estado_id==1? false: (reserva.reserva_estado_id==2? true: extras_check_out.length!=0)) : !sePuedeGrabar? (reserva.reserva_estado_id==1 || reserva.reserva_estado_id==2?false : extras_check_out.length!=0): false">
      <v-expansion-panels v-model="panel7" class="">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="pt-2 pb-2 py-0 px-4 text-h6" style="cursor: default">
            <div>
              Extras Check Out
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel7 = panel7 === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2 pb-2">
            <v-row  :class="reserva.siniestro=='1'? 'pb-4':''">
              <v-col cols="12" class="pt-0">
                <v-row>
                  <v-col cols="6" sm="2" md="2" v-if="reserva.siniestro == '1'">
                    Fecha Siniestro
                    <FechaPickerVue
                      :disabled="(reserva.reserva_estado_id == 3 || reserva.reserva_estado_id == 5) ? true : false"
                      v-model="reserva.fecha_siniestro"
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col cols="6" sm="2" md="2">
                    Siniestro
                    <v-radio-group
                      v-model="reserva.siniestro"
                      :disabled="reserva.reserva_estado_id==3 || reserva.reserva_estado_id==5"
                      row
                      hide-details
                      class="mt-0"
                    >
                      <v-radio
                        label="Si"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="No"
                        value="0"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="6" sm="2" md="2" v-if="reserva.siniestro == '1'">
                    Realizó Denuncia
                    <v-radio-group
                      v-model="reserva.realizo_denuncia"
                      :disabled="reserva.reserva_estado_id==3 || reserva.reserva_estado_id==5"
                      row
                      hide-details
                      class="mt-0"
                    >
                      <v-radio
                        label="Si"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="No"
                        value="0"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="6" sm="2" md="2" v-if="reserva.siniestro == '1'">
                    Adjunta Denuncia
                    <v-radio-group
                      v-model="reserva.adjunta_denuncia"
                      :disabled="reserva.reserva_estado_id==3 || reserva.reserva_estado_id==5"
                      row
                      hide-details
                      class="mt-0"
                    >
                      <v-radio
                        label="Si"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="No"
                        value="0"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="6" sm="2" md="2" v-if="reserva.siniestro == '1'">
                    Foto Denuncia
                    <v-radio-group
                    :disabled="reserva.reserva_estado_id==3 || reserva.reserva_estado_id==5"
                      v-model="reserva.foto_denuncia"
                      row
                      hide-details
                      class="mt-0"
                    >
                      <v-radio
                        label="Si"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="No"
                        value="0"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="6" sm="2" md="2" v-if="reserva.siniestro == '1'">
                    Lugar Siniestro
                    <v-text-field
                    :disabled="reserva.reserva_estado_id==3 || reserva.reserva_estado_id==5"
                      v-model="reserva.lugar_siniestro"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" v-if="reserva.siniestro == '1'"  class="py-0">
                    Testigos del siniestro
                    <v-textarea
                    :disabled="reserva.reserva_estado_id==3 || reserva.reserva_estado_id==5"
                      hide-details
                      outlined
                      dense
                      rows="2"
                      v-model="reserva.testigos_siniestro"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="6" v-if="reserva.siniestro == '1'"  class="py-0">
                    Lesionados
                    <v-textarea
                    :disabled="reserva.reserva_estado_id==3 || reserva.reserva_estado_id==5"
                      outlined
                      dense
                      rows="2"
                      v-model="reserva.lesionados_siniestro"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider v-if="reserva.siniestro=='1'"></v-divider>
            <v-row v-if="instancia != 'prefactura' && reserva.reserva_estado_id!=3 && reserva.reserva_estado_id!=5" :class="reserva.siniestro=='1'? 'pt-8':''">
              <v-col cols="6" sm="4" md="4"  class="py-1">
                Tipo
                <v-autocomplete
                  v-model="filtroExtrasCO.extra_id"
                  item-text="nombre"
                  item-value="extra_id"
                  :items="todos_extras_disp"
                  hide-details
                  outlined
                  dense
                  @change="rellanarExtrasCampos(3)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-1">
                Cantidad
                <v-text-field
                  :key="acuAux"
                  v-model="filtroExtrasCO.cantidad"
                  outlined
                  dense
                  hide-details
                  type="number"
                  @change="recalcularCantidadPrecioExtra(3)"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-1">
                Precio
                <TextFieldMoneyVue
                  v-model="filtroExtrasCO.precio"
                  @change="recalcularCantidadPrecioExtra(3)"                  
                  v-bind:properties="{prefix: '$', 'hide-details': true, 'validate-on-blur': boolString == 'true' }"
                />
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-1">
                Importe
                <TextFieldMoneyVue
                  v-model="filtroExtrasCO.importe"
                  v-bind:properties="{prefix: '$', 'hide-details': true, 'readonly': true, 'filled': true, 'validate-on-blur': boolString == 'true' }"
                />
              </v-col>
              <v-col cols="6" sm="2" md="2"  class="py-2">
                <v-btn class="mt-4" color="info" @click="agregarExtra(3)">
                  AGREGAR
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="">
              <v-col cols="12" class="pt-0 pb-0">
                <v-data-table
                  class="cebra elevation-2 mt-2"
                  :headers="header"
                  :items="extras_check_out"
                  :loading="loadCO"
                  dense
                  hide-default-footer
                  :items-per-page="-1"
                  :search="searchCO"
                  item-key="extra_id"
                >
                  <!-- Buscador en el datatable -->
                  <!-- <template v-slot:top>
                    <v-row class="d-flex justify-end pa-2" no-gutters>
                      <v-col cols="6" sm="3">
                        <SearchDataTableVue
                          v-model="searchCO"
                        />
                      </v-col>
                    </v-row>
                  </template> -->
                  <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                  <template
                    v-for="header in header.filter((header) => header.hasOwnProperty('formatter'))"
                    v-slot:[`item.${header.value}`]="{ value }"
                  >
                    {{ header.formatter(value) }}
                  </template>
                  <!-- Acciones -->
                  <template v-slot:[`item.accion`]="{ item }" >
                    <v-btn icon small @click="quitarExtra(item)" :disabled="reserva.reserva_estado_id == 5" v-if="item.a_facturar == 0 && reserva.reserva_estado_id!=3 && reserva.reserva_estado_id!=5"><v-icon small color="error" title="Quitar extra">fas fa-times</v-icon></v-btn>
                  </template>
                  <!-- ultima row para el total -->
                  <template slot="body.append">
                    <tr class="font-weight-bold">
                      <th colspan="3" style="font-size: 0.9em;"  class="text-right">TOTAL</th>
                      <th class="text-center" style="font-size: 0.9em;" colspan="3">
                        <div class="pr-13">
                          {{ format_money(totalCheckOut) }}
                        </div>
                      </th>
                    </tr>
                  </template>
                  <!-- Msj que se mostrara si no existen resultados -->
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No hay extras para mostrar
                    </v-alert>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                Observaciones
                <v-text-field
                  v-model="reserva.observaciones_check_out"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <!-- Formas de Pago -->
    <v-col cols="12" class="pt-0 pb-0 px-1">
      <FormaPago
        :datos="fp"
        @setFormasPago="formasPagoComponente"
      />
    </v-col>
    <!-- Resumen -->
    <v-col cols="12" class="px-1 pt-1">
      <v-expansion-panels v-model="panel8" class="">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="pt-2 pb-2 py-0 px-4 text-h6" style="cursor: default">
            <div>
              Resumen
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel8 = panel8 === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2 pb-2">
            <v-row class="">
              <v-col cols="12" sm="12" md="6" v-if="!sePuedeGrabar || (facturas_asociadas.length==2 && (facturas_asociadas[0].facturada!=0 || facturas_asociadas[1].facturada!=0))">
                <v-data-table
                  class="cebra elevation-2"
                  :headers="headerResumen"
                  :items="facturas_asociadas"
                  :loading="loadR"
                  dense
                  :search="searchR"
                  item-key="pos"
                  sort-by="factura"
                >
                  <!-- Buscador en el datatable -->
                  <template v-slot:top>
                    <v-row class="d-flex justify-end pa-2" no-gutters>
                      <v-col cols="6" sm="3">
                        <SearchDataTableVue
                          v-model="searchR"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <!-- totales -->
                  <template  v-slot:[`item.total`]="{ item }">
                    {{ format_money(item.total) }}
                  </template>
                  <!-- Acciones -->
                  <template v-slot:[`item.acciones`]="{ item }">
                    <div v-if="facturas_asociadas.length==1">                      
                      <div v-if="item.facturada==1/* && item.es_factura==1*/">
                        <v-btn icon small @click="descargar(item)"><v-icon small color="purple" title="Descargar Factura">fas fa-file-download</v-icon></v-btn>
                        <v-btn icon small @click="notaCredito(item, 1)"><v-icon small color="orange" title="Nota de Crédito">fas fa-file-alt</v-icon></v-btn>
                      </div>
                      <div v-else>
                        <v-btn icon small @click="facturar(item)"><v-icon small color="info" title="Facturar">fas fa-file-alt</v-icon></v-btn>
                      </div>
                    </div>
                    <div v-if="facturas_asociadas.length == 2 && (facturas_asociadas[0].venta_id!=0 || facturas_asociadas[1].venta_id!=0)">
                      <div v-if="item.pos == 0">
                        <v-btn 
                          v-if="item.facturada == 1 /*&& facturas_asociadas[1].es_factura == 0 && facturas_asociadas[1].facturada == 1*/"
                          icon small @click="descargar(item)"><v-icon small color="purple" title="Descargar Factura">fas fa-file-download</v-icon></v-btn>
                      </div>
                      <div v-if="item.pos == 1">
                        <v-btn 
                          v-if="item.facturada == 1 /*&& facturas_asociadas[0].es_factura == 1 && facturas_asociadas[0].facturada == 1*/"
                          icon small @click="descargar(item)"><v-icon small color="orange" title="Descargar Nota de Crédito">fas fa-file-download</v-icon></v-btn>
                        <v-btn 
                          v-if="item.facturada == 0 /*&& facturas_asociadas[0].es_factura == 1 && facturas_asociadas[0].facturada == 1*/"
                          icon small @click="notaCredito(item,2)"><v-icon small color="orange" title="Nota de Crédito">fas fa-file-alt</v-icon></v-btn>
                      </div>
                    </div>
                  </template>
                  <!-- Msj que se mostrara si no existen resultados -->
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No hay datos por mostrar
                    </v-alert>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" :md="facturas_asociadas.length==0? '12':'6'">
                <v-simple-table class="elevation-2">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Concepto
                        </th>
                        <th class="text-center">
                          % Descuento
                        </th>
                        <th class="text-right">
                          Precio
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Alquiler</td>
                        <td></td>
                        <td class="text-right">{{ format_money(alquiler) }}</td>
                      </tr>
                      <tr>
                        <td>Descuento</td>
                        <td class="text-center">{{descuento}}%</td>
                        <td class="text-right">
                          <template>
                            <TextFieldMoneyVue
                              v-model="descuentoImporte"
                              @blur="calcularDescuento()"
                              v-bind:properties="{prefix: '$', 'validate-on-blur': true,
                                filled: reserva.reserva_estado_id!=1 && reserva.reserva_estado_id!=2,
                                readonly: reserva.reserva_estado_id!=1 && reserva.reserva_estado_id!=2,
                                'hide-details': boolString == 'true',
                              }"
                            />
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td>Extras</td>
                        <td></td>
                        <td class="text-right"> {{ format_money(totalExtras) }} </td>
                      </tr>
                      <tr>
                        <td>Extras Check Out</td>
                        <td></td>
                        <td class="text-right"> {{ format_money(totalCheckOut) }} </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Sub Total</td>
                        <td></td>
                        <td class="text-right font-weight-bold"> {{ format_money(subTotal) }} </td>
                      </tr>
                      <tr v-if="efectivoCobrado != 0">
                        <td>Efectivo Cobrado</td>
                        <td></td>
                        <td class="text-right"> {{ format_money(efectivoCobrado) }} </td>
                      </tr>
                      <tr>
                        <td>Total Efectivo</td>
                        <td></td>
                        <td class="text-right">
                          <template>
                            <TextFieldMoneyVue
                              v-model="totalEfectivo"
                              @blur="verificarEfectivo()"
                              v-bind:properties="{
                                prefix: '$',
                                'validate-on-blur': true,
                                'filled': reserva.reserva_estado_id == 5 || reserva.reserva_estado_id == 3,
                                'readonly': reserva.reserva_estado_id == 5 || reserva.reserva_estado_id == 3,
                                'hide-details': boolString == 'true',
                              }"
                            />
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td>Redondeo</td>
                        <td></td>
                        <td class="text-right">
                          <template>
                            <TextFieldMoneyVue
                              v-model="redondeo"
                              @blur="verificarRedondeo()"
                              v-bind:properties="{
                                prefix: '$',
                                'hide-details': true,
                                'filled': reserva.reserva_estado_id == 5 || reserva.reserva_estado_id == 3,
                                'readonly': reserva.reserva_estado_id == 5 || reserva.reserva_estado_id == 3,
                                'validate-on-blur': boolString == 'true',
                              }"
                            />
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td>Total Tarjeta</td>
                        <td></td>
                        <td class="text-right"> {{ format_money(totalTarjeta) }} </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Total</td>
                        <td></td>
                        <td class="text-right font-weight-bold"> {{ format_money(total) }} </td>
                      </tr>
                      <tr :style="pendienteDePago!=0? 'color:red;' : ''">
                        <td class="font-weight-bold">Pendiente de Pago</td>
                        <td></td>
                        <td class="text-right font-weight-bold"> {{ format_money(pendienteDePago) }} </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col cols="12" class="pt-0 px-1">
      <!-- Botones para guardar o cancelar -->
      <v-row>
        <!--  class="d-flex justify-end align-center" -->
        <v-col cols="12">
          <v-row>
            <v-col cols="12" sm="6" :class="!pantallaChica ? '' : 'd-flex flex-direction-column justify-center'" >
              <v-btn class="mr-6 pt-1" color="error" @click="anularPrefactura()"
                v-if="instancia=='prefactura' && reserva.reserva_estado_id==1 && facturas_asociadas.length==1"> ANULAR PREFACTURA </v-btn>
              <v-btn class="mr-6 pt-1" color="error" @click="cancelarReserva()"
                v-if="instancia!='prefactura' && reserva.reserva_estado_id!=3 && reserva.reserva_estado_id!=5"> CANCELAR RESERVA</v-btn>
                <v-btn
                  v-if="reserva.reserva_estado_id == 5 && puedeReabrir == 1 && permisoReabrir == 1"
                  color="info" title="Reabrir reserva" @click="reabrirReserva()"> REABRIR </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex" :class="!pantallaChica ?  'justify-end' : 'flex-direction-column justify-center'">
              <v-btn
                class="mr-2"
                color="secondary"
                @click="volver()"
              >
                VOLVER
              </v-btn>
              <v-btn
                v-if="instancia=='prefactura' && reserva.reserva_estado_id==1 && facturas_asociadas.length == 0"
                color="info"
                nombre=""
                @click="controlarGrabar()"
              >PREFACTURA</v-btn>
              <v-btn
                color="success"
                v-if="instancia!='prefactura' && reserva.reserva_estado_id!=3 && reserva.reserva_estado_id!=5"
                @click="controlarGrabar()"
              >
                {{ reserva.reserva_estado_id == 1 ? 'GRABAR CHECK-IN' : reserva.reserva_estado_id == 2? 'GRABAR CHECK-OUT' : 'GRABAR' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-dialog
          v-model="datos.activo"
          width="450"
          :persistent="datos.activo"
          scrollable
        >
          <v-card>
            <v-card-title class="d-flex justify-center">
              <v-icon class="text-center" color="orange" big>fas fa-question-circle</v-icon>
              <div style="word-break: normal;" class="text-center">{{ cadena }}</div>
            </v-card-title>
            <v-card-text v-if="instancia!='prefactura' && reserva.reserva_estado_id==1">
              Nro. de Contrato
                <v-text-field
                  v-model="contrato"
                  type="number"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
            </v-card-text>
            <v-card-text>
              <v-simple-table class="cebra">
                <template v-slot:default>
                  <tbody>
                    <tr class="font-weight-bold">
                      <td >Total de Efectivo</td>
                      <td class="text-right">{{ format_money(datos.totalEfectivo) }}</td>
                    </tr>
                    <tr class="font-weight-bold">
                      <td >Total de Tarjetas</td>
                      <td class="text-right">{{format_money(datos.totalTarjeta)}}</td>
                    </tr>
                    <tr v-if="instancia=='prefactura'"  class="font-weight-bold">
                      <td >Total Prefactura</td>
                      <td class="text-right"> {{ format_money(datos.totalPrefactura) }} </td>
                    </tr>
                    <tr class="font-weight-bold">
                      <td >Total</td>
                      <td class="text-right"> {{ format_money(datos.total) }} </td>
                    </tr>
                    <tr class="font-weight-bold" :style="datos.pendienteDePago>0? 'color:red;' : ''">
                      <td >Pendiente de Pago</td>
                      <td class="text-right"> {{ format_money(datos.pendienteDePago) }} </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <!-- BOTONES -->
            <v-card-actions class="d-flex justify-center pb-4">
              <v-btn
                class="mr-2"
                color="secondary"
                @click="datos.activo = false"
              >cancelar</v-btn>
              <v-btn
                class="mr-2"
                color="info"
                @click="grabarFacturar();"
              >{{ instancia!='prefactura'? 'Grabar' : 'Facturar' }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-row>
</div>  
</template>

<script>
import BtnConfirmarVue from '../../components/util/BtnConfirmar.vue';
import FechaPickerVue from '../../components/util/FechaPicker.vue';
import HoraPickerVue from '../../components/util/HoraPicker.vue';
import SearchDataTableVue from '../../components/util/SearchDataTable.vue';
import TextFieldMoneyVue from '../../components/util/TextFieldMoney.vue';
import { format_money, roundNumber, cadenaVacia, objetoNoVacio } from '../../util/utils';
import FormaPago from '../../components/ventas/FormaPago.vue'
import moment from 'moment';
import { facturaA4 } from '../../util/plantillas/pdfs';
import download from 'downloadjs'
import router from '../../router';

export default {
  name: 'UnaReserva',
  data(){
    return{
      reserva_id: this.$route.params.id,
      format_money: format_money,
      panel: 0,
      panel2: 0,
      panel3: 0,
      panel4: 0,
      panel5: 0,
      panel6: 0,
      panel7: 0,
      panel8: 0, 
      estado: null,
      //extras pre factura
      searchPF: '',
      loadPF: false,      
      //extras check in
      searchCI: '',
      loadCI: false,
      //extras check out
      searchCO: '',
      loadCO: false,
      //resumen
      loadR: false,
      searchR: '',
      //btnConfirmar
      load: false,
      //init formulario
      estados:[],
      extras_reserva: [], //todos los que ya están usados
      todos_extras: [],
      facturas_asociadas: [],
      lugares: [],
      marcas: [],
      modelos: [],
      vehiculos: [], 
      reserva: [],
      reservaCopia:[],
      permiso_ver_editar: null,
      tipos_documento:[],
      condiciones_iva: [],
      canales: [],
      dominio: [],
      //Variables
      instancia: '',
      sePuedeGrabar: false,
      //Extras
      extras_check_in: [],
      extras_prefactura: [],
      extras_ci_p:[],
      extras_check_out: [],
      todos_extras_disp: [],
      filtroExtras:{
        articulo_codigo: null,
        cantidad: 1,
        diario: null,
        extra_id: null,
        formula: null,
        nombre: null,
        precio: null,
        importe: null,
      },
      totalExtras: 0,
      filtroExtrasCO:{
        articulo_codigo: null,
        cantidad: 1,
        diario: null,
        extra_id: null,
        formula: null,
        nombre: null,
        precio: null,
        importe: null,
      },
      totalCheckOut: 0,
      acuAux: 0,
      header:[
        { text: 'Nombre', value: 'nombre' },
        { text: 'Cantidad', value: 'cantidad', align: 'end' },
        { text: 'Precio', value: 'precio', align:'end', formatter: format_money },
        { text: 'Importe', value: 'importe', align:'end', formatter: format_money },
        { text: 'Accion', value: 'accion', filtreable: false, sorteable: false, align:'center',},
      ],
      //Formas de pago
      fp: {
        monto_cobrar: 0, //neto
        pto_vta: {
          sucursal_codigo: 0, 
          empresa_codigo: 0,
          canal_codigo: 0,
          codigo: 0 //pto_codigo (entrega = retiro)
        },
        venta_id: this.$route.params.id, //reserva_id
        tipo_venta: this.$route.query.origen == 'RENTLY' ? 18 : 25, 
        articulos: []
      },
      detalleFP: [],
      //resumen
      alquiler: 0,
      descuento: 0,
      descuentoImporte: 0,
      subTotal: 0,
      efectivoCobrado: 0,
      totalEfectivo: 0,
      redondeo: 0,
      totalTarjeta: 0,
      totalInteres: 0,
      totalEfectivoFp: 0,
      total: 0,
      pendienteDePago: 0,
      indexFacAsoc: 0,
      headerResumen:[
        { text: 'Venta ID', value: 'venta_id', },
        { text: 'Factura', value: 'factura', },
        { text: 'Tipo', value: 'tipo', },
        { text: 'Total', value: 'total', },
        { text: 'Acciones', value: 'acciones', align: 'center' },
      ],
      pdf: null,
      //dialog del grabar
      datos:{
        activo: false,
        cadena: '',
        totalEfectivo: 0,
        totalTarjeta: 0,
        total: 0,
        totalPrefactura: 0,
        pendienteDePago: 0,
        instancia: '',
      },
      cadena: '',
      contrato: null,
      comprobante_caja_id_pago: null,
      comprobante_caja_id_anulacion: null,
      permisoReabrir: 0,
      puedeReabrir: 0,
      puedeReabrirComprobantes: [],
      pantallaChica: false,
      boolString: "true",
      cobrosAsociar: []
    }
  },
  created(){
    let pos = this.$route.path.indexOf('prefactura');
    if(pos != -1){ //es para prefactura
      this.instancia = 'prefactura'
    }
    if (!objetoNoVacio(this.$route.query) || !this.$route.query.hasOwnProperty('origen') || (this.$route.query.origen != 'HQ' && this.$route.query.origen != 'RENTLY')){
      this.$store.dispatch('show_snackbar', {
        text: 'No se puede determinar la reserva con los parámetros ingresados.',
        color: 'error',
      })
      setTimeout(() => [router.push({path: '/index/reservas'})], 3000)
      return
    }
    this.init();
  },
  methods:{
    async init(){
      this.$store.state.loading = true;
      const res = await this.$store.dispatch('reservas/iniciarFormulario',{ 
        reserva_id: this.reserva_id, 
        funcion_codigo: parseInt(161), 
        origen: this.$route.query.origen == 'HQ' ? 1 : 0 
      })
      this.$store.state.loading = false;
      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', {
          text: res.message,
          color: 'error',
        })
      }
      this.estados            = res.estados;
      this.extras_reserva     = res.extras_reserva;
      this.todos_extras       = res.todos_extras;
      this.facturas_asociadas = res.facturas_asociadas;
      this.depurarExtasTodos();
      this.separarExtras();
      this.lugares            = res.lugares;
      this.marcas             = res.marcas;
      this.modelos            = res.modelos;
      this.vehiculos          = res.vehiculos;
      this.reserva            = await res.reserva[0];
      this.reservaCopia       = JSON.parse(JSON.stringify(this.reserva))
      this.permiso_ver_editar = res.permiso_ver_editar;
      this.tipos_documento    = res.tipos_documento;
      this.condiciones_iva    = res.condiciones_iva;
      this.canales            = res.canales
      //
      this.detPtoVta();      

      this.estado = this.reserva.reserva_estado_id;
      //this.reserva.cliente_cond_iva = this.reserva.cliente_cond_iva ? this.reserva.cliente_cond_iva : 5; // se establece en consumidor final si viene vacío
      
      this.sePuedeGrabar = this.facturas_asociadas.length == 0;
      if(this.facturas_asociadas.length == 1){
        this.comprobante_caja_id_pago = this.facturas_asociadas[0].comprobante_caja_id;
      }
      if(this.facturas_asociadas.length == 2){
        this.facturas_asociadas[0].pos = 0;
        this.facturas_asociadas[1].pos = 1;
      }
      
      this.alquiler         = this.reserva.vehiculo_precio;
      this.descuento        = parseFloat(this.reserva.descuento);
      this.descuentoImporte = roundNumber(this.reserva.descuento_importe, 4);
      this.recalcularTotales();

      this.efectivoCobrado = this.reserva.efectivo_cobrado

      this.reserva.fecha_siniestro  = this.reserva.fecha_siniestro ? this.reserva.fecha_siniestro : moment(new Date()).format('DD/MM/YYYY');
      this.reserva.siniestro        = this.reserva.siniestro.toString();
      this.reserva.realizo_denuncia = this.reserva.realizo_denuncia.toString();
      this.reserva.adjunta_denuncia = this.reserva.adjunta_denuncia.toString();
      this.reserva.foto_denuncia    = this.reserva.foto_denuncia.toString();

      this.reserva.realizo_denuncia = this.reserva.realizo_denuncia == 0? '0' : this.reserva.realizo_denuncia;
      this.reserva.adjunta_denuncia = this.reserva.adjunta_denuncia == 0? '0' : this.reserva.adjunta_denuncia;
      this.reserva.foto_denuncia    = this.reserva.foto_denuncia == 0? '0' : this.reserva.foto_denuncia;

      this.contrato = parseInt(this.reserva.contrato_numero) != 0 ? this.reserva.contrato_numero : null;

      this.redondeo = this.reserva.redondeo;
      
      this.permisoReabrir             = res.permiso;
      this.puedeReabrir               = res.puede_reabrir;
      this.puedeReabrirComprobantes = res.tipos_comprobantes;
      this.pantallaChica              = this.$vuetify.breakpoint.xs;
    },
    //Recibe el array Extras y los separa para trabajarlos en 3 array distintos para cada sección
    separarExtras(){
      let n = this.extras_reserva.length
      for(let i=0; i < n;i++){
        if(this.extras_reserva[i].check_in == 1 || this.extras_reserva[i].pre_factura == 1/* || (this.extras_reserva[i].pre_factura == 1 && (this.facturas_asociadas.length == 2 || this.facturas_asociadas.length == 0))*/){
          //this.extras_check_in.push(this.extras_reserva[i])
          this.extras_ci_p.push(this.extras_reserva[i])
        }
        if(this.extras_reserva[i].check_out == 1){
          this.extras_check_out.push(this.extras_reserva[i])
        }
        /*if(this.extras_reserva[i].pre_factura == 1 *&& !cadenaVacia(this.instancia) && (this.facturas_asociadas.length == 1 || this.facturas_asociadas.length == 0)*){
          this.extras_prefactura.push(this.extras_reserva[i])
        }*/
      }
    },
    //De TODOS los extras que existen genera una copia que contiene solo los que esten disponibles para escoger (Cualquier extra)
    depurarExtasTodos(){
      let pos;
      this.todos_extras_disp = [];
      for(let i=0; i < this.todos_extras.length;i++){
        pos = this.extras_reserva.map(elem => elem.extra_id).indexOf(this.todos_extras[i].extra_id)
        if(pos == -1){
          this.todos_extras_disp.push(this.todos_extras[i])
        }
      }
    },
    //Calcula individualmente los acumulados de los extras por tipo de instancia
    recalcularTotales(){
      let  acu = 0;
      this.extras_ci_p.forEach(e => acu += parseFloat(e.importe));
      this.totalExtras = acu;
      acu = 0;
      this.extras_check_out.forEach(e => acu += parseFloat(e.importe));
      this.totalCheckOut = acu;
      this.calcularDescuento();
    },
    recalcularCantidadPrecioExtra(extraVal){
      let filtroExtras;
      //if(extraVal == 1) filtroExtras = 'filtroExtrasPrefac'
      if(extraVal == 2) filtroExtras = 'filtroExtras'
      if(extraVal == 3) filtroExtras = 'filtroExtrasCO'
      if(this[filtroExtras].extra_id){
        this[filtroExtras].importe = this[filtroExtras].diario == 1? (this.reserva.dias * parseInt(this[filtroExtras].cantidad) * this[filtroExtras].precio) : (parseInt(this[filtroExtras].cantidad) * this[filtroExtras].precio);
        this.acuAux++;
      }
    },
    rellanarExtrasCampos(extraVal){
      let filtroExtras;
      //if(extraVal == 1) filtroExtras = 'filtroExtrasPrefac'
      if(extraVal == 2) filtroExtras = 'filtroExtras'
      if(extraVal == 3) filtroExtras = 'filtroExtrasCO'

      if(this[filtroExtras].extra_id){
        let aux = this.todos_extras_disp.filter(e => e.extra_id == this[filtroExtras].extra_id);
        aux[0].cantidad = this[filtroExtras].cantidad;
        this[filtroExtras] = JSON.parse(JSON.stringify(aux[0]));
        this[filtroExtras].importe = aux[0].diario == 1? (this.reserva.dias * aux[0].cantidad * aux[0].precio) : (aux[0].cantidad * aux[0].precio)
      }else{
        this.limpiarExtras(filtroExtras);
      }
    },
    agregarExtra(extraVal){
      let filtroExtras;
      let arrExtras;
      let campo;
      let obj1;
      /*if(extraVal == 1){
         filtroExtras = 'filtroExtrasPrefac'
         arrExtras = 'extras_prefactura'
         campo = 'pre_factura'
      }*/
      if(extraVal == 2){
        filtroExtras = 'filtroExtras'
        arrExtras = 'extras_ci_p'
        campo = 'check_in'
      } 
      if(extraVal == 3){
        filtroExtras = 'filtroExtrasCO'
        arrExtras = 'extras_check_out'
        campo = 'check_out'
      }
      if(this[filtroExtras].extra_id){
        let obj = this[filtroExtras];
        obj.pre_factura = 0;
        obj.check_in = 0;
        obj.check_out = 0;
        obj.a_facturar = 0;
        obj[campo] = 1;
        this[arrExtras].push(obj)
        this.extras_reserva.push(obj);
        this.depurarExtasTodos();
        this.limpiarExtras(filtroExtras);
        this.recalcularTotales();
      }
    },
    quitarExtra(item){
      let extraTipo;
      /*if(item.pre_factura == 1){
        extraTipo = 'extras_prefactura'
      }*/
      if(item.check_in == 1 || item.pre_factura == 1){
        extraTipo = 'extras_ci_p'
      }
      if(item.check_out == 1){
        extraTipo = 'extras_check_out'
      }
      this[extraTipo] = this[extraTipo].filter(e => e.extra_id != item.extra_id);
      this.extras_reserva = this.extras_reserva.filter(e => e.extra_id != item.extra_id);
      this.depurarExtasTodos();
      this.recalcularTotales();
    },
    limpiarExtras(filtroExtras){
      this[filtroExtras] = {
        articulo_codigo: null,
        cantidad: 1,
        diario: null,
        extra_id: null,
        formula: null,
        nombre: null,
        precio: null,
        importe: null,
      }
    },
    detPtoVta(){
      if(this.reserva.lugar_retiro_id){ 
        let aux = [];
        aux = this.lugares.filter(e => e.lugar_id == this.reserva.lugar_retiro_id);
        if(aux.length == 1){
          this.fp.pto_vta.sucursal_codigo = aux[0].sucursal_codigo
          this.fp.pto_vta.empresa_codigo = aux[0].empresa_codigo
          this.fp.pto_vta.canal_codigo = aux[0].canal_id
          this.fp.pto_vta.codigo = aux[0].pto_vta_codigo //pto_codigo (entrega = retiro)
        }
      }
    },
    calcularDescuento(){
      if(!this.descuentoImporte){
        this.descuentoImporte = 0;
      }
      this.descuento = parseFloat(this.alquiler) != 0 ? (parseFloat(this.descuentoImporte) / parseFloat(this.alquiler))*100 : 0;
      this.descuento = roundNumber(this.descuento,2) * -1
      this.calcularSubTotal();
    },
    calcularSubTotal(){
      this.subTotal = this.alquiler + this.descuentoImporte + /*this.totalPrefactura + this.totalCheckIn*/this.totalExtras + this.totalCheckOut;
      this.fp.monto_cobrar = this.subTotal;
      this.calcularTotal();
    },
    //Determina el total a pagar 
    calcularTotal(){
      //this.total = parseFloat(this.subTotal) + parseFloat(this.redondeo) + parseFloat(this.totalTarjeta) + parseFloat(this.totalInteres);  
      this.total = parseFloat(this.redondeo) + parseFloat(this.totalEfectivoFp) + parseFloat(this.totalTarjeta);
      //if(this.reserva.reserva_estado_id != 5 && this.reserva.reserva_estado_id != 3 && this.reserva.reserva_estado_id != undefined){
        this.calcularPendientePago();
      //}
    },
    //Asigna las cosas que emite el componente de formas de pago
    formasPagoComponente(objFp){
      this.totalTarjeta = objFp.total_tarjeta;
      this.totalInteres = objFp.total_interes;
      this.totalEfectivoFp = objFp.total_efectivo;
      this.detalleFP = objFp.detalle_fp
      this.cobrosAsociar = objFp.cobros_asociar
      this.calcularTotal();
    },
    //Determina el pendiente de pago
    calcularPendientePago(){
      //efectivoFp + redondeo - (totalEfectivo  + efectivoCobrado)
      //let aux = this.total - ( parseFloat(this.efectivoCobrado) + parseFloat(this.totalEfectivo) + parseFloat(this.totalTarjeta) );
      let aux = this.totalEfectivoFp + parseFloat(this.redondeo) - ( parseFloat(this.efectivoCobrado) + parseFloat(this.totalEfectivo) );
      this.pendienteDePago = aux;
    },
    verificarEfectivo(){
      if(!this.totalEfectivo){
        this.totalEfectivo = 0;
      }
      let aux = this.totalEfectivoFp + parseFloat(this.redondeo) - ( parseFloat(this.efectivoCobrado) + parseFloat(this.totalEfectivo) );
      if(aux < 0){
        this.totalEfectivo = 0;
        return this.$store.dispatch('show_snackbar', {
          text: 'El valor ingresado da un Pediente de Pago negativo. Revisar.',
          color: 'warning',
        })
      }
      this.calcularPendientePago();
    },
    verificarRedondeo(){
      if(!this.redondeo){
        this.redondeo = 0;
      }
      if(parseFloat(this.redondeo) < 0 || 50 < parseFloat(this.redondeo)){
        this.redondeo = 0;
        return this.$store.dispatch('show_snackbar', {
          text: 'El valor de redondeo debe ser entre 0 y 50.',
          color: 'warning',
        })
      }
      this.calcularTotal();
    },
    async getDatosFactura (comp_id) {
      this.$store.state.loading = true
      let respuesta = await this.$store.dispatch('caja/datos_factura', { comp_id: comp_id })
      this.$store.state.loading = false
      if (respuesta.exito == 1) {
        return respuesta.datos
      } else {
        this.$swal.fire({
          icon: 'error',
          title: respuesta.message
        })
        return null
      }
    },
    async descargar(item){
      this.$store.state.loading = true
      const datos = await this.getDatosFactura(item.comprobante_caja_id)
      this.$store.state.loading = false
      if (datos == null) return

      this.$store.state.loading = true
        this.pdf = await facturaA4(datos)
        download(this.pdf, `${item.factura}.pdf`,'application/pdf')
      this.$store.state.loading = false
    },
    async facturar(item){
      this.$store.state.loading = true
      let respuesta = await this.$store.dispatch('afip/permiteFacturar', {
        comprobante_caja_id: item.comprobante_caja_id
      })
      this.$store.state.loading = false

      if (respuesta.resultado != 1) {
        return this.$swal.fire({
          icon: 'error',
          title: respuesta.msj
        })
      }
      
      let modal = await this.$swal.fire({
        icon: 'info',
        title: `¿Está seguro de emitir un comprobante del tipo: ${respuesta.comprobante_tipo}?`,
        confirmButtonText: 'Facturar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      })
      if (!modal.isConfirmed) return

      this.$store.state.loading = true
      let fcPeticion = await this.$store.dispatch('afip/getFactura', {
        comprobante_caja_id: item.comprobante_caja_id
      })
      this.$store.state.loading = false

      if (fcPeticion.resultado == 0) {
       return this.$swal.fire({
          icon: 'error',
          title: 'Error al generar la Factura',
          text: fcPeticion.msj
        })
      }
      this.$swal.fire({
        icon: 'success',
        title: 'Se generó con Éxito la Factura',
        text: 'En breve se descargará el comprobante.',
        timer: 3000
      })

      this.$store.state.loading = true
      const datos = await this.getDatosFactura(comprobante.id)
      this.$store.state.loading = false

      if (datos != null) {
        this.$store.state.loading = true
          this.pdf = await facturaA4(datos)
        this.$store.state.loading = false
        download(this.pdf, `${item.factura.toString().length > 5 ? item.factura : `FC-${this.reserva_id}` }.pdf`,'application/pdf')
      }
      setTimeout(() => [location.reload()], 3000)
    },
    async notaCredito(item, opc){
      let modal = await this.$swal.fire({
        icon: 'info',
        title: `¿Está seguro de emitir la Nota de Crédito?`,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      })
      if (!modal.isConfirmed) return

      let comp_aux = item.comprobante_caja_id
      
      this.$store.state.loading = true
      if(opc == 1){
        //this.$store.state.loading = true
        const res = await this.$store.dispatch(`reservas/anularPrefactura`, {reserva_id: this.reserva_id, comprobante_caja_id: null})
        //
        if(res.resultado == 0){
          this.$store.state.loading = false
          return this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error',
          })
        }
        comp_aux = res.comprobante_anula_id;
      }
      let ncPeticion = await this.$store.dispatch('afip/getNotaCredito', {
        comprobante_caja_id: comp_aux
      })
      this.$store.state.loading = false

      if (ncPeticion.resultado != 1) {
        setTimeout(() => [location.reload()], 5000)
        return this.$swal.fire({
          icon: 'error',
          title: 'No fue posible generar la Nota de Crédito',
          text: ncPeticion.msj
        })
      }
      this.$swal.fire({
        icon: 'success',
        title: 'Se genero con éxito la Nota de Crédito',
        text: 'En breve se descargará el comprobante.',
        timer: 3000
      })
      
      
      this.$store.state.loading = true
      const datos = await this.getDatosFactura(comp_aux)
      this.$store.state.loading = false

      if (datos != null) {
        this.$store.state.loading = true
          this.pdf = await facturaA4(datos)
        this.$store.state.loading = false
        download(this.pdf, `${item.factura.toString().length > 5 ? item.factura : `NC-${this.reserva_id}` }.pdf`,'application/pdf')
      }
      setTimeout(() => [location.reload()], 5000)
    },
    //boton rojo
    async anularPrefactura(){
      this.$swal.fire({
        icon: 'warning',
        title: `¿Estás seguro de anular el Pago Adelantado de la reserva Nro.: ${this.reserva_id}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(async (res) => {
        if(res.isConfirmed){
          let obj = {
            reserva_id: this.reserva_id,
            comprobante_caja_id: null,
            origen_id: this.$route.query.origen == 'RENTLY' ? 0 : 1
          }
          this.$store.state.loading = true
          const res = await this.$store.dispatch(`reservas/anularPrefactura`, obj)

          if(res.resultado == 0){
            this.$store.state.loading = false
            return this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error',
            })
          }
          this.comprobante_caja_id_anulacion = res.comprobante_anula_id;
          
          if(this.facturas_asociadas.length==1 && this.facturas_asociadas[0].es_factura==1 && this.facturas_asociadas[0].facturada==1){
            let ncPeticion = await this.$store.dispatch('afip/getNotaCredito', {
              comprobante_caja_id: this.comprobante_caja_id_anulacion
            })

            if (ncPeticion.resultado == 0) {
              this.$store.state.loading = false
              setTimeout(() => [location.reload()], 3000)
              return this.$swal.fire({
                icon: 'error',
                title: 'No fue posible generar la Nota de Crédito',
                text: ncPeticion.msj
              })
            }
            this.$swal.fire({
              icon: 'success',
              title: 'Se genero con éxito la Nota de Crédito',
              text: 'En breve se descargará el comprobante.',
              timer: 3000
            })

            //this.$store.state.loading = true
            const datos = await this.getDatosFactura(item.comprobante_caja_id)
            //this.$store.state.loading = false

            if (datos != null) {
              //this.$store.state.loading = true
              this.pdf = await facturaA4(datos)
              this.$store.state.loading = false
              download(this.pdf, `NC-${this.reserva_id}.pdf`,'application/pdf')
            }
          }
          this.$store.state.loading = false
          this.$swal.fire({
            icon: 'success',
            title: 'El Pago adelantado' + ` fue anulado con éxito` ,
            text: ``,
            timer: 4000
          });
          setTimeout(() => [location.reload()], 3000)
        }
      })
    },
    //boton rojo
    async cancelarReserva(){
      this.$swal.fire({
        icon: 'warning',
        title: `¿Esta seguro de Cancelar la reserva Nro.: ${this.reserva_id}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(async(resp) => {
        if(resp.isConfirmed){ 
          let obj = {
            reserva_id: this.reserva_id,
            origen_id: this.$route.query.origen == 'RENTLY' ? 0 : 1
          }
          this.$store.state.loading = true
          const res = await this.$store.dispatch(`reservas/cancelarReserva`, obj)
          this.$store.state.loading = false
          if(res.resultado == 0){
            return this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error',
            })
          }
          this.$swal.fire({
            icon: 'success',
            title: 'La Reserva fue cancelada con éxito',
            text: ``,
            timer: 4000
          });
          setTimeout(() => [location.reload()], 3000)
        } 
      })
    },
    //Valida campos de la Prefactura y check In
    validarCampos(){
      let error = {}
      if( !this.reserva.cliente_apellido ){
        error.text = 'Debe completar el campo Apellido.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.cliente_nombre ){
        error.text = 'Debe completar el campo Nombre.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.fecha_nacimiento ){
        error.text = 'Debe completar el campo Fecha de nacimiento.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.tipo_documento ){
        error.text = 'Debe completar el campo Tipo de Documento.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.cliente_nif){
        error.text = 'Debe completar el campo DNI/Pasaporte.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.cliente_cond_iva){
        error.text = 'Debe completar el campo Cond. de IVA.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.cliente_pais){
        error.text = 'Debe completar el campo País.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.cliente_provincia){
        error.text = 'Debe completar el campo Provincia.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.cliente_ciudad){
        error.text = 'Debe completar el campo Ciudad.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.cliente_cp){
        error.text = 'Debe completar el campo Código Postal.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.cliente_domicilio){
        error.text = 'Debe completar el campo Domicilio.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.cliente_telefono){
        error.text = 'Debe completar el campo Teléfono.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.cliente_email){
        error.text = 'Debe completar el campo Email.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.cliente_licencia){
        error.text = 'Debe completar el campo Licencia N°.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.cliente_licencia_vto){
        error.text = 'Debe completar el campo Lic. Vencimiento.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.origen_id){
        error.text = 'Debe completar el campo Canal.'
        error.color = 'warning'
        return error
      }
      if( (this.reserva.empresa_cuit && (!this.reserva.empresa_nombre || !this.reserva.empresa_domicilio || !this.reserva.empresa_cond_iva))
      || (this.reserva.empresa_nombre && (!this.reserva.empresa_cuit || !this.reserva.empresa_domicilio || !this.reserva.empresa_cond_iva))
      || (this.reserva.empresa_domicilio && (!this.reserva.empresa_nombre || !this.reserva.empresa_cuit || !this.reserva.empresa_cond_iva))
      || (this.reserva.empresa_cond_iva && (!this.reserva.empresa_nombre || !this.reserva.empresa_domicilio || !this.reserva.empresa_cuit))){
        error.text = 'Debe completar todos los campos de la sección Datos Empresa.'
        error.color = 'warning'
        return error
      }
      if(this.reserva.empresa_cuit && this.reserva.empresa_cuit.length < 11){
        error.text = 'Por favor, ingresar un  nro. de CUIT válido.'
        error.color = 'info'
        return error
      }
      if( !this.reserva.lugar_retiro_id ){
        error.text = 'Debe completar el campo Lugar de entrega.'
        error.color = 'warning'
        return error
      }
      if( !this.reserva.retiro_hora ){
        error.text = 'Debe completar el campo Hora de entrega.'
        error.color = 'warning'
        return error
      }
      if( this.pendienteDePago < 0 ){
        error.text = 'Se tiene un pendiente de pago negativo. Revisar.'
        error.color = 'warning'
        return error
      }

      if(this.instancia != 'prefactura'){
        if( !this.reserva.retiro_dia ){
          error.text = 'Debe completar el campo Día de entrega.'
          error.color = 'warning'
          return error
        }
        if( !this.reserva.lugar_entrega_id ){
          error.text = 'Debe completar el campo Lugar de Devolución.'
          error.color = 'warning'
          return error
        }
        if( !this.reserva.entrega_dia){
          error.text = 'Debe completar el campo Día de Devolución.'
          error.color = 'warning'
          return error
        }
        if( !this.reserva.entrega_hora){
          error.text = 'Debe completar el campo Hora de entrega.'
          error.color = 'warning'
          return error
        }
        if(cadenaVacia(this.reserva.dias)){
          error.text = 'Debe completar el campo Días de reserva.'
          error.color = 'warning'
          return error
        }
        if( !this.reserva.marca_id ){
          error.text = 'Debe completar el campo Marca.'
          error.color = 'warning'
          return error
        }
        if( !this.reserva.vehiculo){
          error.text = 'Debe completar el campo Dominio.'
          error.color = 'warning'
          return error
        }
        if( !this.reserva.vehiculo_modelo_id ){
          error.text = 'Debe completar el campo Modelo.'
          error.color = 'warning'
          return error
        }
        if( !this.reserva.kilometro_inicio ){
          error.text = 'Debe completar el campo KM Inicio.'
          error.color = 'warning'
          return error
        }
      }
      return error;
    },
    //Valida campos requeridos del Check Out
    validarCamposCO(){
      let error = {};
      if(/*this.reserva.kilometro_fin*/cadenaVacia(this.reserva.kilometro_fin)){
        error.text = 'Se debe rellenar el campo KM Fin.';
        error.color = 'warning';
        return error;
      }
      if(parseInt(this.reserva.kilometro_inicio) >= parseInt(this.reserva.kilometro_fin)){
        error.text = 'KM Fin debe ser mayor que KM Inicio.';
        error.color ='warning';
        return error;
      }      
      if(roundNumber(this.pendienteDePago,2) != 0){
        error.text = 'Existen Pendientes de Pagos, no es posible realizar Checkout.';
        error.color = 'warning';
        return error;
      }
      return error;
    },
    validarPagosCompletados(){
      let error = {}
      let n = this.detalleFP.length;
      if(n>0){
        let band = false;
        let i = 0;
        while(i < n && !band){
          if(this.detalleFP[i].completa == 0){
            band = true;
          }
          i++;
        }
        if(band){
          error.text = 'Existen pagos con datos incompletos. Por favor verificar.';
          error.color = 'warning';
        }
      }
      return error;
    },
    controlarGrabar(){
      //Controles del Check IN y Prefactura
      let error;
      if(this.reserva.reserva_estado_id == 1){
        error = this.validarCampos();
        if(Object.keys(error).length != 0){
          return this.$store.dispatch('show_snackbar', error)
        }
      }
      //Controles para el Check Out
      error = {}
      if(this.reserva.reserva_estado_id==2 && this.instancia!='prefactura'){
        error = this.validarCamposCO();
        if(Object.keys(error).length != 0){
          return this.$store.dispatch('show_snackbar', error)
        }
      }
      //Control Generales a toda instancia
      error = {};
      error = this.validarPagosCompletados();
      if(Object.keys(error).length != 0){
        return this.$store.dispatch('show_snackbar', error)
      }
      // 31-03-2025 por MMURILLO, controlo que no haya cobros pendientes de asociar
      let pendAsociar = this.cobrosAsociar.filter(element => element.asociada == 0)
      if (pendAsociar.length > 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe asociar todos los Cobros Online realizados por el Cliente para continuar.',
          color: 'warning',
        })
        return
      }
      //prepara el objecto para el dialog final
      this.cadena = this.instancia!='prefactura'? (this.reserva.reserva_estado_id == 1 ? 'Está seguro de realizar el Check-In para la Reserva Nº:  '+ this.reserva_id+' ?' :(this.reserva.reserva_estado_id == 2 ? 'Está seguro de realizar el Check-Out para la Reserva Nº:  '+ this.reserva_id+' ?' : 'Esta seguro de realizar grabar para la Reserva Nº:  '+ this.reserva_id+' ?')) : 
                  'Esta seguro de realizar una Prefactura para la Reserva Nº:  '+ this.reserva_id+' ?'
      this.datos.cadena = this.cadena;
      this.datos.totalEfectivo = this.totalEfectivo;
      this.datos.totalTarjeta = this.totalTarjeta;
      this.datos.total = this.total;
      this.datos.totalPrefactura = this.totalEfectivo + this.totalTarjeta;
      this.datos.pendienteDePago = this.pendienteDePago;
      this.datos.instancia = this.instancia; 
      this.datos.activo = true
    },
    async grabarFacturar(){
      let reserva = {};
      let cliente = {};
      let siniestro = {};
      let extras = [];
      let detalle_fp = [];

      if( this.reserva.reserva_estado_id==1 && this.instancia!='prefactura' && !this.contrato ){
        let error = {};
        error.text = 'Debe ingresar Nro. de Contrato.'
        error.color = 'warning'
        return this.$store.dispatch('show_snackbar', error)
      }
      this.datos.activo = false;
      reserva = {
        'reserva_id': this.reserva.reserva_id,
        'lugar_retiro_id': this.reserva.lugar_retiro_id,
        'lugar_entrega_id': this.reserva.lugar_entrega_id,
        'retiro_dia': this.reserva.retiro_dia,
        'retiro_hora': this.reserva.retiro_hora,
        'entrega_dia': this.reserva.entrega_dia, 
        'entrega_hora': this.reserva.entrega_hora, 
        'importe_total': this.total,
        'importe_pagado': null,
        'reserva_fecha': this.reserva.reserva_fecha,
        'dias': this.reserva.dias,
        'km_inicio': this.reserva.kilometro_inicio,
        'km_fin': this.reserva.kilometro_fin,
        'descuento': roundNumber(this.descuento, 2), //roundNumber(this.reserva.descuento,2),
        'descuento_importe': roundNumber(this.descuentoImporte, 4) > 0 ? (-1)*roundNumber(this.descuentoImporte, 4) : roundNumber(this.descuentoImporte, 4),
        'importe_nafta': this.reserva.importe_nafta,
        'importe_tapiz': this.reserva.importe_tapiz,
        'empresa_cuit': this.reserva.empresa_cuit,
        'empresa_nombre': this.reserva.empresa_nombre,
        'empresa_domicilio': this.reserva.empresa_domicilio,
        'empresa_cond_iva': this.reserva.empresa_cond_iva,
        'importe_pendiente': this.pendienteDePago,
        'total_efectivo': roundNumber(parseFloat(this.efectivoCobrado) + parseFloat(this.totalEfectivo),2),
        'total_tarjeta': this.totalTarjeta,
        'retiro_domicilio': this.reserva.retiro_domicilio,
        'entrega_domicilio': this.reserva.entrega_domicilio,
        'contrato_numero': this.contrato,
        'reserva_redondeo': this.redondeo,
        'vehiculo_precio': this.reserva.vehiculo_precio,
        'observacion': this.reserva.observaciones_check_out, 
      };
      cliente = {
        'cliente_id': this.reserva.cliente_id,
        'cliente_nombre': this.reserva.cliente_nombre, 
        'cliente_apellido': this.reserva.cliente_apellido,
        'tipo_doc': this.reserva.tipo_documento,
        'cliente_direccion': this.reserva.cliente_domicilio,
        'cliente_telefono': this.reserva.cliente_telefono,
        'cliente_mail': this.reserva.cliente_email,
        'fecha_nacimiento': this.reserva.fecha_nacimiento,
        'cond_iva': this.reserva.cliente_cond_iva,
        'codigo_postal': this.reserva.cliente_cp,
        'cliente_provincia': this.reserva.cliente_provincia,
        'cliente_pais': this.reserva.cliente_pais,
        'cliente_localidad': this.reserva.cliente_ciudad,
        'canal_id': this.reserva.origen_id,
        'cliente_licencia': this.reserva.cliente_licencia,
        'licencia_fecha_vto': this.reserva.cliente_licencia_vto,
        'cliente_nif': this.reserva.cliente_nif
      }
      siniestro = {
        'siniestro': this.reserva.siniestro,
        'siniestro_denuncia': this.reserva.realizo_denuncia,
        'siniestro_adjunto': this.reserva.adjunta_denuncia,
        'foto_denuncia': this.reserva.foto_denuncia,
        'siniestro_lugar': this.reserva.lugar_siniestro,
        'siniestro_testigos': this.reserva.testigos_siniestro,
        'siniestro_lesionados': this.reserva.lesionados_siniestro,
        'fecha_siniestro': this.reserva.fecha_siniestro,
        'monto_reparacion': this.reserva.monto_reparacion,
      }
      extras = this.extras_reserva;
      detalle_fp = this.detalleFP;
      
      let obj = {
        reserva: reserva,
        extras: extras,
        detalle_fp: detalle_fp,
        origen_id: this.$route.query.origen == 'RENTLY' ? 0 : 1
      };
      let metodo = '';
      if(this.reserva.reserva_estado_id == 1){
        obj.cliente = cliente;
        metodo = this.instancia!='prefactura'? 'checkIn' : 'prefactura';
      }else { obj.siniestro = siniestro; metodo = 'checkOut'; }

      this.$store.state.loading = true
      const res = await this.$store.dispatch(`reservas/${metodo}`, obj)
      this.$store.state.loading = false
      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', {
          text: res.message,
          color: 'error',
        })
      }
      this.$swal.fire({
        icon: 'success',
        title: 'El ' + (this.reserva.reserva_estado_id == 1? (this.instancia!='prefactura' ? 'Check In' : 'Pago adelantado'):'Check Out') + ` fue guardado con éxito` ,
        text: ``,
        timer: 4000
      })

      if(this.instancia == 'prefactura'){
        //
        this.$store.state.loading = true
        let fcPeticion = await this.$store.dispatch('afip/getFactura', {
          comprobante_caja_id: res.comprobante_caja_id
        });
        this.$store.state.loading = false
        if(fcPeticion.resultado == 0){
          setTimeout(() => [location.reload()], 3000)
          return this.$store.dispatch('show_snackbar', {
            text: fcPeticion.msj,
            color: 'error',
          })
        }
      }

      //router.push({ path: `/index/reservas` });
      setTimeout(() => [location.reload()], 3000)
    },
    volver(){
      //router.push({ path: `/index/reservas` });
      window.close();
    },
    //hace la accion de reabrir la reserva, cambia los estados
    async reabrirReserva(){
      if(this.puedeReabrir == 1){ //cumple las condiciones de que NO tiene check out en TELECOM.COMPROBANTE_CAJA
        let modal = await this.$swal.fire({
          icon: 'warning',
          title: 'Confirmar acción',
          text: `¿Está seguro de reabrir la reserva: ${this.reserva_id}?`,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true
        });
        if (!modal.isConfirmed) return
        
        let tiene107 = this.puedeReabrirComprobantes.filter(e => e.tipo_comp_id == 107);
        let estadoNuevo = 2;

        let modal1
        let dialog_reabrir = true;
        if(tiene107.length == 0){
          //abrir modal para elegir que estado: abierto o en renta
          
          modal1 = await this.$swal.fire({
            icon: 'question',
            title: 'Seleccione un estado',
            text: `Debe seccionar el nuevo estado en el cual quedará la reserva ${this.reserva_id} reabierta:`,
            confirmButtonText: 'Abierto',
            denyButtonText: 'En renta',
            denyButtonColor: '#2196F3', //#4EBCEF
            showDenyButton: true,
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
          });
          
          if(modal1.hasOwnProperty('value')){
            if(modal1.isConfirmed){
              estadoNuevo = 1;
            }
          } else dialog_reabrir = false;       
        }
        if(dialog_reabrir){
          this.$store.state.loading = true
          const res = await this.$store.dispatch('reservas/cambiarEstadoReservaReabrir', { 
            reserva_id: this.reserva_id,
            estado_cambiar: estadoNuevo,
            origen_id: this.$route.query.origen == 'RENTLY' ? 0 : 1
          });
          this.$store.state.loading = false

          if(res.resultado == 0){
            return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error',})
          }
          this.$swal.fire({
            icon: 'success',
            title: `Reapertura exitosa` ,
            text: `La reserva ${this.reserva_id} ha sido reabierta con éxito.`,
            timer: 4000
          });
          setTimeout(() => [location.reload()], 3000)
        }

      }else{
        return this.$store.dispatch('show_snackbar', {text: 'No es posible Reabrir esta reserva porque ya tiene un Check Out asociado.', color: 'info', });
      }
    }
  },
  computed:{
  },
  components:{
    FechaPickerVue, TextFieldMoneyVue, SearchDataTableVue, HoraPickerVue, BtnConfirmarVue, FormaPago, 
  },
  watch:{
    descuentoImporte(val){
      if(val > 0){
        this.descuentoImporte *= -1
      }
    },
    'reserva.siniestro':{
      handler: function(val){
        if(val == false){
          this.reserva.realizo_denuncia = '0';
          this.reserva.adjunta_denuncia = '0';
          this.reserva.foto_denuncia = '0';
        }
      },
      deep: true,
    },
    'datos.activo': {
      handler: function(val){
        if(val == false){
          this.contrato = '';
        }
      },
      deep: true,
    }
  }
}
</script>

<style>

</style>