<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-card
          flat
          outlined
          :loading="load"
        >
          <v-card-title>
            <v-icon left>far fa-credit-card</v-icon> Formas de Pago
            <v-btn small icon @click="getFinanciaciones(2)"><v-icon small color="success">fas fa-search</v-icon></v-btn>
            <v-spacer></v-spacer>
            <div class="d-flex">
              <v-btn 
                icon 
                title="Agregar Cobros Asociados"
                :class="`pulse-${$vuetify.theme.dark ? 'dark mt-3 mr-4' : 'light mt-3 mr-4'}`"
                v-if="dialogAsociarFP.cobros_asociar.length > 0 && dialogAsociarFP.cobros_asociar.filter(element => element.asociada == 0).length > 0"
                @click="asociarFP()"
              >
                <v-icon :color="$vuetify.theme.dark ? 'warning' : 'info'">far fa-credit-card</v-icon>
              </v-btn>
              <v-btn 
                color="success"
                v-if="dialogAsociarFP.cobros_asociar.length > 0 && dialogAsociarFP.cobros_asociar.filter(element => element.asociada == 0).length > 0"
                @click="asociarFP()"
                title="Heredar Cobros Online"
              >Heredar Cobros Online</v-btn>
            </div>
          </v-card-title>
          <v-card-text class="pt-4">
            <!-- Agregar una Financiación -->
            <v-form @submit.prevent="agregarFP()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="2" md="2" :class="$vuetify.breakpoint.mobile ? 'py-2' : 'py-1'">
                  <text-field-money
                    v-model="fp.monto"
                    label="Monto"
                    v-bind:properties="{
                      prefix: '$',
                      'hide-details': true,
                      // readonly: nuevo == 0
                    }"
                    @blur="getFinanciaciones(1)"
                  />
                </v-col>
                <v-col cols="12" sm="4" md="4" :class="$vuetify.breakpoint.mobile ? 'py-2' : 'py-1'">
                  <v-autocomplete
                    v-model="fp.selectedFormaPago"
                    label="Forma pago"
                    item-text="descripcion"
                    :items="financiaciones"
                    hide-details
                    return-object
                    clearable
                    outlined
                    dense
                    @change="controlFP()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="2" md="2" :class="$vuetify.breakpoint.mobile ? 'py-2' : 'py-1'">
                  <text-field-money
                    v-model="fp.montoCuotas"
                    label="Monto Cuotas"
                    class="py-0 pr-2"
                    v-bind:properties="{
                      prefix: '$',
                      readonly: true,
                      filled: true,
                      'hide-details': true
                    }"
                  />
                </v-col>
                <v-col cols="6" sm="2" md="2" :class="$vuetify.breakpoint.mobile ? 'py-2' : 'py-1'">
                  <text-field-money
                    v-model="fp.fpImporte"
                    label="Importe"
                    class="py-0"
                    v-bind:properties="{
                      prefix: '$',
                      readonly: true,
                      filled: true,
                      'hide-details': true
                    }"
                  />
                </v-col>
                <v-col cols="12" sm="2" md="2" class="d-flex justify-end pt-0">
                  <v-btn
                    color="info"
                    type="submit"
                    class="mt-1"
                    style="margin-top: -2px;"
                  >
                    Agregar
                  </v-btn>
                </v-col>
              </v-row>
              <!-- Msj Alerta Efectivo -->
              <v-expand-transition>
                <div v-if="totalEfectivo < 0">
                  <v-alert
                    type="error"
                    border="left"
                  >
                    Existen diferencias entre las formas de pago ingresadas y el total de la venta. Verificar
                  </v-alert>
                </div>
              </v-expand-transition>
            </v-form>
            <!-- Listado de Formas de Pago -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12" class="py-0">
                <v-data-table
                  class="elevation-2 cebra"
                  :items="detalleFP"
                  item-key="index_local"
                  :headers="headers"
                  :items-per-page="-1"
                  hide-default-footer
                  dense
                >
                  <!-- campos con formato -->
                  <template v-slot:[`item.tarjeta_importe`]="{ value }">
                    {{ format_money(value) }}
                  </template>
                  <template v-slot:[`item.importe_cuotas`]="{ value }">
                    {{ format_money(value) }}
                  </template>
                  <template v-slot:[`item.total_final`]="{ value }">
                    {{ format_money(value) }}
                  </template>
                  <!-- acciones -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn small icon color="info" @click="verFP(item)" v-on="on">
                          <v-icon small>fas fa-list</v-icon>
                        </v-btn>
                      </template>
                      <span>Detalle Forma de Pago</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="cadenaVacia(item.id)">
                      <template v-slot:activator="{ on }">
                        <v-btn small icon color="error" @click="quitarFP(item)" v-on="on">
                          <v-icon small>fas fa-times-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Quitar</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.completa == 0">
                      <template v-slot:activator="{ on }">
                        <v-btn small icon color="warning" @click="verFP(item)" v-on="on">
                          <v-icon small style="cursor: pointer;" :class="`pulse-${$vuetify.theme.dark ? 'dark' : 'light'}`">fas fa-exclamation-triangle</v-icon>
                        </v-btn>
                      </template>
                      <span>Faltan completar datos</span>
                    </v-tooltip>
                  </template>
                  <!-- fila del total de fp -->
                  <template slot="body.append">
                    <tr class="font-weight-bold">
                      <th style="font-size: 14px" >Subtotal</th>
                      <th style="font-size: 14px" class="text-center">
                        {{ format_money(montoFP) }}
                      </th>
                      <th></th>
                      <th style="font-size: 14px" class="text-right">
                        {{ format_money(totalTarjeta) }}
                      </th>
                      <th></th>
                    </tr>
                  </template>
                  <!-- no datos -->
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="450"
                      type="info"
                      border="left"
                      dense
                      text
                    >
                      No se agregaron Formas de Pago
                    </v-alert>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <!-- Totales -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-6" justify="center">
              <v-col cols="6" sm="3" md="3" class="pt-0 d-flex" style="font-size: 18px; cursor: default;">
                <v-icon color="success" left>fas fa-money-bill-alt</v-icon> Efectivo
              </v-col>
              <v-col cols="6" sm="3" md="3" class="pt-0 d-flex d-flex justify-end" style="font-size: 18px; cursor: default;">
                {{ format_money(totalEfectivo) }}
              </v-col>
            </v-row>
            <v-row :no-gutters="$vuetify.breakpoint.xs" justify="center" :class="$vuetify.breakpoint.mobile ? 'mt-2' : ''">
              <v-col cols="6" sm="3" md="3" class="pt-0 d-flex primary--text font-weight-bold" style="font-size: 20px; cursor: default;">
                <v-icon left>fas fa-hand-holding-usd</v-icon> Total
              </v-col>
              <v-col cols="6" sm="3" md="3" class="pt-0 d-flex d-flex justify-end primary--text font-weight-bold" style="font-size: 20px; cursor: default;">
                {{ format_money(totalEfectivo + totalTarjeta) }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal Seleccionar FP -->
    <ModalSelectFP
      :datos="dialogSelectFP"
      @setearModalSelectFP="setModalSelectFP"
      @selectedFP="setearFP"
    />
    <!-- Modal Ver FP -->
    <ModalVerFP 
      :datos="dialogVerFP"
      @setearModalVerFP="setModalVerFP"
      @addDataFP="completarDataFP"
    />
    <!-- Modal Asociar FP -->
    <ModalAsociarFP 
      :datos="dialogAsociarFP"
      @setearModAsocFP="setModAsocFP"
      @fpComplete="completarFP"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { format_money, cadenaVacia, objetoNoVacio, roundNumber } from '../../util/utils'
import TextFieldMoney from '../util/TextFieldMoney.vue'
import ModalSelectFP from './ModalSelectFP.vue'
import ModalVerFP from './ModalVerFP.vue'
import ModalAsociarFP from '../reservas/ModalAsociarFP.vue';
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    monto_cobrar: {
      get(){
        return cadenaVacia(this.datos.monto_cobrar) ? 0 : roundNumber(this.datos.monto_cobrar, 2)
      }
    },
    pto_vta: {
      get(){
        return this.datos.pto_vta
      }
    },
    venta_id: {
      get(){
        return !cadenaVacia(this.datos.venta_id) ? this.datos.venta_id : 0
      }
    },
    tipo_venta: {
      get(){
        return !cadenaVacia(this.datos.tipo_venta) ? this.datos.tipo_venta : 0
      }
    },
    articulos: {
      get(){
        return this.datos.articulos
      }
    }
  }, 
  data() {
    return {
      panel: 0,
      format_money: format_money,
      cadenaVacia: cadenaVacia,
      totalEfectivo: 0,
      totalTarjeta: 0,
      totalInteres: 0,
      totalVenta: 0,
      fp: {
        monto: 0,
        selectedFormaPago: {},
        montoCuotas: 0,
        fpImporte: 0
      },
      financiaciones: [],
      headers: [
        { text: 'Monto', value: 'tarjeta_importe', sortable: false, filterable: false, width: '1', align: 'right' },
        { text: 'Forma pago', value: 'descripcion', sortable: false, filterable: false, align: 'center' },
        { text: 'Monto cuotas', value: 'importe_cuotas', sortable: false, filterable: false, width: '100', align: 'right' },
        { text: 'Total', value: 'total_final', sortable: false, filterable: false, width: '1', align: 'right' },
        { text: '', value: 'actions', sortable: false, filterable: false, align: 'right', width: 180 }
      ],
      detalleFP: [],
      selectedItem: {},
      montoFP: 0,
      load: false,
      laposEliminados: [],
      dialogSelectFP: {
        activo: false,
        detalles_fp: []
      },
      dialogVerFP: {
        activo: false,
        selected_fp: {},
        tarjetas: [],
        bancos: [],
        cuenta_bancos: [],
        tipos_movimientos: [],
        empresa_codigo: 0
      },
      dialogAsociarFP: {
        activo: false,
        cobros_asociar: [],
        detalles_forma_pago: []
      }
    }
  },
  components: {
    TextFieldMoney,
    ModalSelectFP,
    ModalVerFP,
    ModalAsociarFP
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.load = true
      let initPeticion = await this.$store.dispatch('financiaciones/init_formas_pago')
      this.load = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
        return
      }
      this.dialogVerFP.tarjetas = initPeticion.tarjetas
      this.dialogVerFP.bancos = initPeticion.bancos
      this.dialogVerFP.cuenta_bancos = initPeticion.cuentas
      this.dialogVerFP.tipos_movimientos = initPeticion.tipos_mov_banco
      this.dialogVerFP.empresa_codigo = this.pto_vta.empresa_codigo
      // verifico si tengo que cargar los detalles de una venta
      if (!cadenaVacia(this.venta_id) && parseInt(this.venta_id) > 0){
        // paso a buscar los detalles de la venta
        this.loadFP()
        return
      }
      // emito los datos iniciales
      this.calcularTotales()
    },
    calcularTotales(){
      if (this.detalleFP.length == 0){
        this.totalEfectivo = this.monto_cobrar
        this.totalVenta = this.monto_cobrar
        this.totalTarjeta = 0
        this.totalInteres = 0
        this.emitirFP()
        return
      }
      // tarjeta sin tener en cuenta intereses
      this.montoFP = this.detalleFP.reduce((sum, item) => sum + roundNumber(item.tarjeta_importe, 2), 0)
      // calculo el total en EFECTIVO
      this.totalEfectivo = roundNumber(this.monto_cobrar - this.montoFP, 2)
      // obtengo el total de INTERES
      this.totalInteres = this.detalleFP.reduce((sum, item) => sum + roundNumber(item.ajuste_x_fp, 2), 0)
      // obtengo el total en TARJETA
      this.totalTarjeta = this.detalleFP.reduce((sum, item) => sum + roundNumber(item.total_final, 2), 0)
      // total de la VENTA
      this.totalVenta = roundNumber(this.totalEfectivo + this.totalTarjeta, 2)
      // emito
      this.emitirFP()
    },
    async loadFP(){
      this.load = true
      let detallesPeticion = await this.$store.dispatch('financiaciones/get_formas_pago_venta', {
        nro_pedido: this.venta_id,
        tipo_venta: this.tipo_venta
      })
      this.load = false
      if (detallesPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: detallesPeticion.msj,
          color: 'error',
        })
        return
      }
      // 31-03-2025 por MMURILLO, guardo los cobros online pendientes de asociar
      this.dialogAsociarFP.cobros_asociar = detallesPeticion.cobros_asociados
      for (let id in detallesPeticion.forma_pago){
        // me quedo con mi detalle actual
        let det = detallesPeticion.forma_pago[id]
        // encuentro el correspondiente en el otro array
        let existe = detallesPeticion.datos.filter(element => element.id == det.id)
        if (existe.length == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo cargar correctamente los detalles de forma de pago. Revisar.',
            color: 'error',
          })
          return
        }
        det.tarjeta_importe = roundNumber(roundNumber(existe[0].importe, 2) - roundNumber(existe[0].ajuste_x_fp, 2), 2)
        det.total_final = roundNumber(existe[0].importe, 2)
        det.ajuste_x_fp = roundNumber(existe[0].ajuste_x_fp, 2)
        det.porcentaje = roundNumber(existe[0].fp_cond_porcentaje, 2)
        det.importe_cuotas = roundNumber(det.total_final/roundNumber(existe[0].cuotas, 2), 2)
        det.completa = 0
        // agrego la propiedad datos para colocar los datos necesarios de la fp
        Object.defineProperty(det, 'datos', {
          value: {
            codigo_autorizacion: existe[0].codigo_autorizacion,
            cuotas: existe[0].cuotas,
            cuotas_importe: det.importe_cuotas,
            tarjeta_numero: existe[0].numero,
            terceros: (cadenaVacia(existe[0].terceros) || existe[0].terceros == 0) ? 0 : 1,
            cliente: (cadenaVacia(existe[0].terceros) || existe[0].terceros == 0) ? 1 : 0,
            titular: existe[0].titular,
            titular_dni: existe[0].titular_dni,
            imei_cotizado: '',
            descuento: '',
            cupon: existe[0].cupon,
            lote: existe[0].lote,
            fecha_cobro: moment(existe[0].fecha).format('DD/MM/YYYY'),
            banco_codigo: det.banco_codigo,
            banco_cuenta: det.banco_cuenta,
            banco_fecha: cadenaVacia(det.banco_fecha) ? null :  moment(det.banco_fecha).format('DD/MM/YYYY'),
            banco_tipo_movimiento: det.banco_tipo_movimiento,
            banco_comprobante: det.banco_numero_comprobante,
            tarjeta_vencimiento:'',
            codigo_seguridad:'',
            cobro_transaccion:''
          },
          writable: true,
          enumerable: true,
          configurable: true
        })
        // agrego en mis detalles de forma de pago
        this.detalleFP.push(det)
      }
      this.controlDatosFP()
    },
    emitirFP(){
      this.$emit('setFormasPago', {
        total_efectivo: this.totalEfectivo,
        total_tarjeta: this.totalTarjeta,
        total_interes: this.totalInteres,
        detalle_fp: this.detalleFP,
        total_venta: this.totalVenta,
        lapos_eliminados: [],
        cobros_asociar: this.dialogAsociarFP.cobros_asociar
      })
    },
    async getFinanciaciones(opcion){
      if (cadenaVacia(this.fp.monto) || roundNumber(this.fp.monto, 2) <= 0){
        this.fp.monto = 0
        return
      }
      // articulos
      let arts = []
      if (this.articulos.length > 0){
        for (let id in this.articulos){
          arts.push(this.articulos[id].articulo_codigo)
        }
      }
      this.financiaciones = []
      this.load = true
      let fpsPeticion = await this.$store.dispatch('financiaciones/get_financiaciones_habilitadas', {
        importe: this.fp.monto,
        sucursal: this.pto_vta.sucursal_codigo,
        tipo_venta: this.tipo_venta,
        manual: 0,
        empresa: this.pto_vta.empresa_codigo,
        canal: this.pto_vta.canal_codigo,
        pto_vta: this.pto_vta.codigo,
        articulos: arts
      })
      this.load = false
      if (fpsPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: fpsPeticion.msj,
          color: 'error',
        })
        return
      }
      if (opcion == 1) this.financiaciones = fpsPeticion.fps
      else{
        let detalles = fpsPeticion.fps
        for (let id in detalles){
          if (detalles[id].cuotas_desde == detalles[id].cuotas_hasta) detalles[id].importe_cuotas = roundNumber(roundNumber(detalles[id].total_final, 2)/roundNumber(detalles[id].cuotas_desde, 2), 2)
          else detalles[id].importe_cuotas = null
        }
        // todo ok, paso a abrir el modal
        this.dialogSelectFP.detalles_fp = detalles
        this.dialogSelectFP.activo = true
      }
    },
    restablecerFP(){
      this.fp.monto = 0
      this.fp.selectedFormaPago = {}
      this.fp.montoCuotas = 0
      this.fp.fpImporte = 0
      this.financiaciones = []
      // this.$refs.montoFormaPago.focus()
    },
    async agregarFP() {
      if (cadenaVacia(this.fp.monto) || roundNumber(this.fp.monto, 2) <= 0){
        this.fp.monto = 0
        return
      }
      if (!objetoNoVacio(this.fp.selectedFormaPago)){
        this.fp.selectedFormaPago = {}
        this.fp.montoCuotas = 0
        this.fp.fpImporte = 0
        return
      }
      // obtengo la diferencia entre el efectivo a cobrar y el importe de tarjeta sin tener en cuenta los intereses por forma de pago
      let dif = roundNumber(roundNumber(this.totalEfectivo, 2) - roundNumber(this.fp.selectedFormaPago.tarjeta_importe, 2), 2)
      if (dif < 0){
        this.$store.dispatch('show_snackbar', {
          text: 'El monto ingresado supera al efectivo disponible. Por favor, pruebe con otro monto.',
          color: 'warning',
        })
        this.fp.selectedFormaPago = {}
        this.fp.montoCuotas = 0
        this.fp.fpImporte = 0
        return
      }else this.totalEfectivo = dif
      // agrego la propiedad datos para colocar los datos necesarios de la fp
      Object.defineProperty(this.fp.selectedFormaPago, 'datos', {
        value: {
          codigo_autorizacion: '',
          cuotas: this.fp.selectedFormaPago.cuotas_desde,
          cuotas_importe: this.fp.selectedFormaPago.importe_cuotas,
          tarjeta_numero: '',
          terceros: 0,
          cliente: 0,
          titular: '',
          titular_dni: '',
          imei_cotizado: '',
          descuento: '',
          cupon: '',
          lote: '',
          fecha_cobro: '',
          banco_codigo:'',
          banco_cuenta:'',
          banco_fecha:'',
          banco_tipo_movimiento:'',
          banco_comprobante:'',
          tarjeta_vencimiento:'',
          codigo_seguridad:'',
          cobro_transaccion:''
        },
        writable: true,
        enumerable: true,
        configurable: true
      })
      // lo agrego a mi detalle de formas de pago
      this.detalleFP.push(this.fp.selectedFormaPago)
      // reestablezco la sección de agregar una forma de pago
      this.restablecerFP()
      // paso a recalcular los totales
      this.calcularTotales()
    },
    controlFP(){
      if (!objetoNoVacio(this.fp.selectedFormaPago)){
        this.fp.selectedFormaPago = {}
        this.fp.montoCuotas = 0
        this.fp.fpImporte = 0
        return
      }
      // seteo el monto de cuotas y el total del pago
      this.fp.montoCuotas = this.fp.selectedFormaPago.importe_cuotas
      this.fp.fpImporte = this.fp.selectedFormaPago.total_final
    },
    verFP(item){
      // asigno el detalle actualmente seleccionado
      this.dialogVerFP.selected_fp = item
      // abro el modal
      this.dialogVerFP.activo = true
    },
    quitarFP(item){
      // controlo a ver si no está en los cobros por asociar
      if (item.hasOwnProperty('solicitud_cobro_id') && !cadenaVacia(item.solicitud_cobro_id)){
        let itemAsocia = this.dialogAsociarFP.cobros_asociar.filter(element => element.solicitud_cobro_id == item.solicitud_cobro_id)
        if (itemAsocia.length > 0){
          let posi = this.dialogAsociarFP.cobros_asociar.indexOf(itemAsocia[0])
          this.dialogAsociarFP.cobros_asociar[posi].asociada = 0
        }
      }
      let pos = this.detalleFP.indexOf(item)
      this.detalleFP.splice(pos, 1)
      this.calcularTotales()
    },
    setModalSelectFP(value){
      this.dialogSelectFP.activo = value
      if (this.dialogSelectFP.activo == false) this.dialogSelectFP.detalles_fp = []
    },
    setModalVerFP(value){
      this.dialogVerFP.activo = value
    },
    setearFP(item){
      this.fp.selectedFormaPago = item
      this.agregarFP()
    },
    completarDataFP(value){
      // busco el registro en los detalles de Formas de Pago
      let existe = this.detalleFP.filter(element => element.index_local == value.index_local)
      if (existe.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Ocurrió un problema al determinar el elemento seleccionado en los detalles de Formas de Pago.',
          color: 'error',
        })
        return
      }
      let pos = this.detalleFP.indexOf(existe[0])
      Object.assign(this.detalleFP[pos].datos, value.datos)
      this.controlDatosFP()
    },
    controlDatosFP(){
      for (let id in this.detalleFP){
        // en principio digo que el detalle está OK
        this.detalleFP[id].completa = 1
        // si todo está OK, no va a entrar en ninguna de las siguientes condiciones
        // tarjeta
        if (this.detalleFP[id].tarjeta == 1 && cadenaVacia(this.detalleFP[id].datos.tarjeta_numero)) this.detalleFP[id].completa = 0
        // código de autorización
        if (this.detalleFP[id].cod_autoriz == 1 && cadenaVacia(this.detalleFP[id].datos.codigo_autorizacion)) this.detalleFP[id].completa = 0
        // cuotas
        if (this.detalleFP[id].cuotas == 1 && cadenaVacia(this.detalleFP[id].datos.cuotas)) this.detalleFP[id].completa = 0
        // cupon
        if (this.detalleFP[id].cupon_lote == 1 && cadenaVacia(this.detalleFP[id].datos.cupon)) this.detalleFP[id].completa = 0
        // lote
        if (this.detalleFP[id].cupon_lote == 1 && cadenaVacia(this.detalleFP[id].datos.lote)) this.detalleFP[id].completa = 0
        // es datos de banco?
        if (this.detalleFP[id].solicita_banco == 1){
          if (cadenaVacia(this.detalleFP[id].datos.banco_codigo) || cadenaVacia(this.detalleFP[id].datos.banco_comprobante) || 
          cadenaVacia(this.detalleFP[id].datos.banco_cuenta) || cadenaVacia(this.detalleFP[id].datos.banco_fecha) || cadenaVacia(this.detalleFP[id].datos.banco_tipo_movimiento)) this.detalleFP[id].completa = 0
        }
      }
      // paso a recalcular los totales
      this.calcularTotales()
    },
    setModAsocFP(value){
      this.dialogAsociarFP.activo = value
    },
    async asociarFP(){
      // articulos
      let arts = []
      if (this.articulos.length > 0){
        for (let id in this.articulos){
          arts.push(this.articulos[id].articulo_codigo)
        }
      }
      this.$store.state.loading = true
      let paraAsociar = this.dialogAsociarFP.cobros_asociar.filter(element => element.asociada == 0)
      for (let id in paraAsociar){
        let financiaPeticion = await this.$store.dispatch('financiaciones/get_financiaciones_habilitadas', {
          importe: paraAsociar[id].importe,
          sucursal: this.pto_vta.sucursal_codigo,
          tipo_venta: this.tipo_venta,
          manual: 0,
          empresa: this.pto_vta.empresa_codigo,
          canal: this.pto_vta.canal_codigo,
          pto_vta: this.pto_vta.codigo,
          articulos: arts
        })
        if (financiaPeticion.resultado == 0){
          this.$store.state.loading = false
          paraAsociar = []
          this.$store.dispatch('show_snackbar', {
            text: financiaPeticion.msj,
            color: 'error',
          })
          return
        }
        let financiaciones = financiaPeticion.fps.filter(element => element.tarjeta_codigo == paraAsociar[id].tarjeta_codigo && element.cuotas_desde == paraAsociar[id].cuotas)
        if (financiaciones.length > 0){
          let unDet = {
            solicitud_cobro_id: paraAsociar[id].solicitud_cobro_id,
            importe: paraAsociar[id].importe,
            financiaciones: financiaciones,
            select_financiacion: financiaciones.length > 1 ? null : financiaciones[0].codigo,
            referencia: `${paraAsociar[id].titular} - ${paraAsociar[id].marca}`
          }
          this.dialogAsociarFP.detalles_forma_pago.push(unDet)
        }
      }
      this.$store.state.loading = false
      // me fijo si solo tengo una sola opcion de financiacion en cada detalle
      let masDeUno = this.dialogAsociarFP.detalles_forma_pago.filter(element => element.financiaciones.length > 1)
      if (masDeUno.length == 0){
        this.completarFP()
        return
      }
      // caso contrario prosigo como siempre
      if (this.dialogAsociarFP.detalles_forma_pago.length > 0) this.dialogAsociarFP.activo = true
      else{
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo inicializar los cobros a asociar ya que no se encontraron coincidencias entre las financiaciones habilitadas y los datos del cobro realizado.',
          color: 'error',
        })
        return
      }
    },
    completarFP(){
      for (let id in this.dialogAsociarFP.detalles_forma_pago){
        let unDet = {}
        let unafinanciacion = this.dialogAsociarFP.detalles_forma_pago[id].financiaciones.filter(element => element.codigo == this.dialogAsociarFP.detalles_forma_pago[id].select_financiacion)
        if (unafinanciacion.length == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo determinar los valores del cobro a agregar.',
            color: 'error',
          })
          return
        }
        // forma de pago seleccionada por el usuario
        Object.assign(unDet, unafinanciacion[0])
        // datos del cobro
        let unCobro = this.dialogAsociarFP.cobros_asociar.filter(element => element.solicitud_cobro_id == this.dialogAsociarFP.detalles_forma_pago[id].solicitud_cobro_id)[0]
        // controlo el efectivo
        let dif = roundNumber(roundNumber(this.totalEfectivo, 2) - roundNumber(unCobro.importe, 2), 2)
        if (dif < 0){
          this.$store.dispatch('show_snackbar', {
            text: 'El monto ingresado supera al efectivo disponible. Por favor, pruebe con otro monto.',
            color: 'warning',
          })
          this.dialogAsociarFP.detalles_forma_pago = []
          return
        }else this.totalEfectivo = dif
        // me quedo con la solicitud de cobro
        unDet.solicitud_cobro_id = this.dialogAsociarFP.detalles_forma_pago[id].solicitud_cobro_id
        // indico que es tarjeta
        unDet.tarjeta = 1
        // indico que esta OK
        unDet.completa = 1
        // datos de la tarjeta
        if (cadenaVacia(unDet.importe_cuotas)) unDet.importe_cuotas = roundNumber(roundNumber(unDet.total_final, 2) / parseInt(unDet.cuotas_desde), 2)
        unDet.datos = {}
        Object.assign(unDet.datos, {
          codigo_autorizacion: unCobro.codigo_autorizacion,
          cuotas: unDet.cuotas_desde,
          cuotas_importe: unDet.importe_cuotas,
          tarjeta_numero: unCobro.tarjeta_numero,
          terceros: 0,
          cliente: 1,
          titular: unCobro.titular,
          titular_dni: unCobro.documento_numero,
          imei_cotizado: '',
          descuento: '',
          cupon: unCobro.cupon,
          lote: unCobro.lote,
          fecha_cobro: moment(unCobro.fecha).format('DD/MM/YYYY'),
          banco_codigo:'',
          banco_cuenta:'',
          banco_fecha:'',
          banco_tipo_movimiento:'',
          banco_comprobante:'',
          tarjeta_vencimiento:'',
          codigo_seguridad:'',
          cobro_transaccion: unCobro.transaccionid
        })
        // lo agrego a mi detalle de formas de pago
        this.detalleFP.push(unDet)
        // paso a recalcular los totales
        this.calcularTotales()
        // marco el cobro como agregado
        let pos = this.dialogAsociarFP.cobros_asociar.indexOf(unCobro)
        this.dialogAsociarFP.cobros_asociar[pos].asociada = 1
      }
      this.dialogAsociarFP.detalles_forma_pago = []
    }
  },
  watch: {
    monto_cobrar: function(){
      this.calcularTotales()
    },
    financiaciones: function(){
      if (this.financiaciones.length > 0){
        for (let id in this.financiaciones){
          if (this.financiaciones[id].cuotas_desde == this.financiaciones[id].cuotas_hasta) this.financiaciones[id].importe_cuotas = roundNumber(roundNumber(this.financiaciones[id].total_final, 2)/roundNumber(this.financiaciones[id].cuotas_desde, 2), 2)
          else this.financiaciones[id].importe_cuotas = null
        }
      }
    },
    detalleFP: function(){
      if (this.detalleFP.length > 0){
        for (let id in this.detalleFP){
          Object.defineProperty(this.detalleFP[id], 'index_local', {
            value: parseInt(id),
            writable: true,
            enumerable: true,
            configurable: true
          })
          if (!cadenaVacia(this.detalleFP[id].ajuste_x_fp)) this.detalleFP[id].ajuste_x_fp = roundNumber(this.detalleFP[id].ajuste_x_fp, 2)
          if (!cadenaVacia(this.detalleFP[id].importe_cuotas)) this.detalleFP[id].importe_cuotas = roundNumber(this.detalleFP[id].importe_cuotas, 2)
          if (!cadenaVacia(this.detalleFP[id].porcentaje)) this.detalleFP[id].porcentaje = roundNumber(this.detalleFP[id].porcentaje, 2)
          if (!cadenaVacia(this.detalleFP[id].tarjeta_importe)) this.detalleFP[id].tarjeta_importe = roundNumber(this.detalleFP[id].tarjeta_importe, 2)
          if (!cadenaVacia(this.detalleFP[id].total_final)) this.detalleFP[id].total_final = roundNumber(this.detalleFP[id].total_final, 2)
        }
      }
    },
    pto_vta: function(){
      if(Object.keys(this.pto_vta).length > 0){
        this.dialogVerFP.empresa_codigo = this.pto_vta.empresa_codigo;
      }
    },
    'pto_vta.empresa_codigo':{
      handler: function(val){
        this.dialogVerFP.empresa_codigo = val;
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
  .pulse-light {
  animation: pulse-light-animation 2s infinite;
  width: 10px;
  height: 10px;
  margin-left: 6px;
  margin-right: 12px;
  border-radius: 50%;
}

@keyframes pulse-light-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.pulse-dark {
  animation: pulse-dark-animation 2s infinite;
  width: 10px;
  height: 10px;
  margin-left: 6px;
  margin-right: 12px;
  border-radius: 50%;
}

@keyframes pulse-dark-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
</style>